/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import "../../SCSS/ContactPage.scss"
import Button from '../../commonModules/UI/Button';
import '../../SCSS/popups.scss';
import { IoCopy } from "react-icons/io5";
import { MdOutlineMail } from "react-icons/md";
import { useFormik } from 'formik';
import { hsContactUpdateSchema } from '../../../Utils/validation';
import { useContactOwners, useContactProps } from '../../hooks/contacts';
import { Tooltip, Spin, Drawer } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getSingleHSContact, updateHSContact } from '../../../API/authCurd';
import { parseDateTimeString } from '../../../Utils/dateFormat';
import { hsObjectSource } from '../../../Utils/staticdata';
import { constructHubSpotFileUrl, deletePropertiesIfExists, extractIds, getAvatarInitials, getNameInitialWithemail, sendEmail } from '../../../Utils/helpers';
import EditableAvatar from './EditableAvatar';
import EditTooltip from './EditTooltip';
import PhoneNumberPopover from './PhoneNumberPopover';
import SearchDropdown from './SearchDropdown';
import MultiselectDropdown from './MultiselectDropdown';
import { sortByConcatenatedString, sortObjectsByAttribute } from '../../../Utils/sortFunctions';
import CompanyAssociation from './associationForms/company/CompanyAssociation';
import ContactAssociation from './associationForms/contact/ContactAssociation';
import DealAssociation from './associationForms/deals/DealAssociation';
import { ErrorMessage } from '../../hooks/hubspot';

const initialValues = {
  email: '',
  hubspot_owner_id: '',
  phone: '',
  lifecyclestage: '',
  hs_lead_status: '',
  hs_object_source: '',
  hs_legal_basis: null,
  is_phone_formatted: false
}

function PreviewButton({ record, onPreviewClick }) {
  return (
    <>
      {/* <Tooltip placement="top" title={'Preview'}> */}
      <button className='preview-btn' onClick={() => { onPreviewClick(record) }}>
        {/* <MdOutlinePreview style={{ fontSize: '18px' }} /> */}
        Preview
      </button>
      {/* </Tooltip> */}
    </>
  );
}

const ContactOffCanvas = ({ show, setShow, record, afterDelete, onClose, viewRecord, openFileUpload, openFileRemove, createNote, sendMessage, removeContactAssociation, handleSetPrimary, removePrimaryAssociation, onContactPreviewClick, onCompanyPreviewClick, onDealPreviewClick }) => {
  const [isSaving, setIsSaving] = useState(false)
  const [copyText, setCopyText] = useState("Copy email to clipboard")
  const { contactOwners, getContactOwners } = useContactOwners();
  const [contactData, setContactData] = useState(null)
  const [contactLoading, setContactLoading] = useState(false);
  const { getContactProps, contactLegalBasis, contactLifecycles, contactLeadStatus } = useContactProps();

  const [contactAssociations, setContactAssociations] = useState([])
  const [companyAssociations, setCompanyAssociations] = useState([])
  const [dealAssociations, setDealAssociations] = useState([])
  const [showLoader, setShowLoader] = useState({ contacts: false, companies: false, deals: false })

  const { errors, resetForm, getFieldProps, handleSubmit, touched, setFieldValue, values } = useFormik({
    initialValues,
    validationSchema: hsContactUpdateSchema,
    onSubmit: (values) => {
      let data = { ...values }
      data.hs_legal_basis = values?.hs_legal_basis?.join(';')
      deletePropertiesIfExists(data, ["hs_object_source"])
      if (data.hubspot_owner_id == 'default') { data.hubspot_owner_id = "" }
      setIsSaving(true)
      updateHSContact(record?.id, data).then((res) => {
        if(res.data?.original?.code == '403'){
          let message = res.data?.original?.error || ""
          sendMessage({ type: 'error', content: <ErrorMessage heading={'Permission denied'} message={message} /> }) 
        } else {
        getSingleContactData(record?.id)
        sendMessage({ content: 'Changes saved' })
        }
      }).catch((err) => { })
        .finally(() => { setIsSaving(false) })
    },
  });

  function getSingleContactData(id, loader = false) {
    if (loader) {
      setContactLoading(true)
      setShowLoader({ contacts: true, companies: true, deals: true })
    }
    return getSingleHSContact(id).then((res) => {
      if (res?.data?.contacts?.[0]) {
        setCompanyAssociations(res?.data?.associations?.companies ? res?.data?.associations?.companies : [])
        setContactAssociations(res?.data?.associations?.associatedContacts ? res?.data?.associations?.associatedContacts : [])
        setDealAssociations(res?.data?.associations?.deals ? res?.data?.associations?.deals : [])
        setContactData(res?.data?.contacts?.[0])
      }
      return
    }).catch(() => { setContactData(null) })
      .finally(() => {
        setShowLoader({ contacts: false, companies: false, deals: false })
        setContactLoading(false)
      })
  }

  useEffect(() => {
    if (contactData) {
      Object.keys(initialValues).forEach((field) => {
        if (field == 'hs_legal_basis') {
          let vals = contactData?.properties?.[field]?.split(';')
          setFieldValue(field, vals)
        } else if (field == 'hubspot_owner_id') {
          setFieldValue(field, contactData?.properties?.[field] ? contactData?.properties?.[field] : "default")
        } else if (field == 'is_phone_formatted') {
          setFieldValue(field, contactData?.properties?.[field] == 'true' ? true : false)
        } else {
          setFieldValue(field, contactData?.properties?.[field] ? contactData?.properties?.[field] : "")
        }
      })
    }
  }, [contactData, setFieldValue])

  function showButtonHandler() {
    getContactOwners()
    getContactProps()
    setShow(true)
    if (record) { getSingleContactData(record?.id, true) }
  }

  useEffect(() => {
    getContactOwners()
    getContactProps()
    setShow(true)
    if (record) { getSingleContactData(record?.id, true) }
  }, [record])

  function closeButtonHandler() {
    setShow(false)
    setIsSaving(false)
    resetForm()
    if (onClose) { onClose(record) }
  }

  const getName = (data) => {
    let name = `${data?.properties?.firstname ? data?.properties?.firstname : ""} ${data?.properties?.lastname ? data?.properties?.lastname : ""}`.trim()
    return name ? name : data?.properties?.email
  }

  const handleCopy = (data) => {
    if (data?.properties?.email) {
      navigator.clipboard.writeText(data.properties.email).then(() => {
        setCopyText('Copied');
        setTimeout(() => {
          setCopyText('Copy email to clipboard');
        }, 2000)
      }).catch(err => { });
    }
  };

  // delete button handler
  const deleteContactHandler = async () => {
    if (afterDelete) {
      afterDelete(record).then((data) => {
        closeButtonHandler()
      }).catch((err) => { })
    }
  }

  const handleUpload = () => {
    openFileUpload(record)
  };

  const handleRemove = () => {
    openFileRemove(record)
  };

  const onPhoneApplyClick = (val, isFormatted) => {
    setFieldValue('phone', val)
    setFieldValue('is_phone_formatted', isFormatted)
  }

  const onUpdateHandler = (load) => {
    if (load) {
      getSingleContactData(record?.id)
    }
  }

  const contactAssociationRemove = async (e, data) => {
    let msg = `${getName(data)} will no longer be associated with ${getName(contactData)}`
    return await removeContactAssociation(msg, 'Remove association')
  }

  const getDealName = (data) => {
    let name = `${data?.properties?.dealname ? data?.properties?.dealname : ""}`.trim()
    return name ? name : '--'
  }

  const dealAssociationRemove = async (e, data) => {
    let msg = `${getDealName(data)} will no longer be associated with ${getName(contactData)}`
    return await removeContactAssociation(msg, 'Remove association')
  }

  const getNameOrDomain = (data) => {
    let name = `${data?.properties?.name ? data?.properties?.name : ""}`.trim()
    return name ? name : data?.properties?.domain ? data?.properties?.domain : '--'
  }

  const companyAssociationRemove = async (e, data) => {
    let msg = `${getNameOrDomain(data)} will no longer be associated with ${getName(contactData)}`
    return await removeContactAssociation(msg, 'Remove association')
  }

  const handleRemovePrimary = async (e, data, primaryKey, companies) => {
    let options = companies?.map((item) => {
      let label = item?.properties?.name ? item?.properties?.name : item?.properties?.domain
      return {
        label: label ? label : '--',
        value: item?.id
      }
    })
    let filteredOpt = options?.filter((item) => { return item?.value != primaryKey })
    let heading = `Remove ${getNameOrDomain(data)} as primary`
    return await removePrimaryAssociation(`Primary company for this contact`, heading, primaryKey, filteredOpt ? filteredOpt : [])
  }

  const handleRemovePrimaryAssociation = async (e, data, primaryKey, companies) => {
    let options = companies?.map((item) => {
      let label = item?.properties?.name ? item?.properties?.name : item?.properties?.domain
      return {
        label: label ? label : '--',
        value: item?.id
      }
    })
    let filteredOpt = options?.filter((item) => { return item?.value != primaryKey })
    let heading = `Remove primary association`
    let subHeading = `${getNameOrDomain(data)} will no longer be associated with ${getName(contactData)}`
    return await removePrimaryAssociation(`Primary company for this contact`, heading, primaryKey, filteredOpt ? filteredOpt : [], subHeading)
  }

  const onContactPreviewButtonClick = (item) => {
    setCompanyAssociations([])
    setContactAssociations([])
    setDealAssociations([])
    setContactData(null)
    resetForm()
    setTimeout(() => {
      onContactPreviewClick(item)
    }, 300)
  }

  return (
    <Drawer placement='right' title={
      <div className='drawer-title'>
        {getName(contactData)}
      </div>
    }
      destroyOnClose={true}
      open={show}
      onClose={closeButtonHandler}
    >
      <div className='drawer-content-wrapper'>
        {contactLoading ? <div className='d-flex justify-content-center align-items-center p-1 my-5'>
          <Spin className="text-white" indicator={<LoadingOutlined style={{ fontSize: '36px', fill: '#2c0036', color: '#2c0036' }} spin />} />
        </div> : <>
          <div className='personal-info-wrap'>
            <EditableAvatar
              // initials={getNameInitialWithemail(contactData?.properties?.firstname, contactData?.properties?.lastname, contactData?.properties?.email)}
              initials={getAvatarInitials(contactData?.properties?.firstname, contactData?.properties?.lastname, contactData?.properties?.email)}
              onUpload={handleUpload}
              onRemove={handleRemove}
              imgUrl={contactData?.properties?.hs_avatar_filemanager_key ? constructHubSpotFileUrl(contactData?.properties?.hs_avatar_filemanager_key) : null}
            />
            <div className='edit-details-wrap'>
              <div className='personal-info'>
                <h6>
                  {getName(contactData)}
                </h6>
                {contactData?.properties?.jobtitle && <p>
                  {contactData?.properties?.jobtitle}
                </p>}
                {contactData?.properties?.email && <p>
                  {contactData?.properties?.email}
                  <Tooltip placement="top" title={copyText}>
                    <IoCopy className={`ms-2`} onClick={() => handleCopy(contactData)} />
                  </Tooltip>
                  <Tooltip placement="top" title={'Email'}>
                    <MdOutlineMail className={`ms-2`} onClick={() => sendEmail(contactData?.properties?.email)} />
                  </Tooltip>
                </p>}
              </div>
              <EditTooltip record={contactData} id={contactData?.id} onUpdate={onUpdateHandler} sendMessage={sendMessage} />
            </div>
          </div>
          <div className='main-btn-wrap'>
            {/* <div className='delete-btn button-wrap' onClick={() => createNote(contactData)}>
              <div className='icon'> <FaRegEdit /></div>
              <p>Note</p>
            </div> */}
            {/* <div className='delete-btn button-wrap' onClick={deleteContactHandler}>
              <div className='icon'> <RiDeleteBin6Line /></div>
              <p>Delete</p>
            </div> */}
          </div></>}
        <div className='form-wrap'>
          <h5>About this contact</h5>
          <div>
            <FloatingLabel label="Email">
              <Form.Control
                type="text"
                name='email'
                {...getFieldProps("email")}
              />
            </FloatingLabel>
            {(touched.email && errors.email) ? <span className='ms-2 text-danger'>{errors.email}</span> : null}
          </div>
          <div>
            <PhoneNumberPopover
              type="text"
              name='phone'
              onApplyClick={onPhoneApplyClick}
              value={values.phone ?? ""}
              placeholder={'Phone Number'}
              placement='right'
              // isFormatted={contactData?.properties?.is_phone_formatted == 'true' ? true : false}
              isFormatted={values?.is_phone_formatted ? true : false}
            />
          </div>
          <SearchDropdown
            label="Contact Owner"
            options={[
              { label: "No Owner", value: 'default' },
              ...sortByConcatenatedString(contactOwners, ['firstName', 'lastName']).map(({ id, firstName, lastName, email, archived }) => ({
                value: id,
                label: `${firstName ? firstName : ""} ${lastName ? lastName : ""}${archived ? ' (Deactivated/Removed) ' : ' '}${email ? "(" + email + ")" : ""}`.trim(),
                archived: archived ? archived : false
              }))]}
            // placeholder="Choose..."
            placeholder="Select an owner"
            onSelect={(value) => setFieldValue('hubspot_owner_id', value)}
            initialValue={values?.hubspot_owner_id ?? "default"}
          />
          <div>
            <FloatingLabel label='Last Contacted' className='custom-select'>
              <Form.Control
                type="text"
                value={contactData?.properties?.notes_last_contacted ? parseDateTimeString(contactData?.properties?.notes_last_contacted, 16) : '--'}
                disabled={true}
              />
            </FloatingLabel>
          </div>
          <SearchDropdown
            label="Lifecycle Stage"
            options={[
              { label: "Select a stage", value: "" },
              ...sortObjectsByAttribute(contactLifecycles, 'displayOrder')
            ]}
            // placeholder="Choose..."
            placeholder="Select a stage"
            onSelect={(value) => setFieldValue('lifecyclestage', value)}
            initialValue={values?.lifecyclestage}
          />
          <SearchDropdown
            label="Lead Status"
            options={[
              { label: "Select a status", value: "" },
              ...sortObjectsByAttribute(contactLeadStatus, 'displayOrder')
            ]}
            // placeholder="Choose..."
            placeholder="Select a status"
            onSelect={(value) => setFieldValue('hs_lead_status', value)}
            initialValue={values?.hs_lead_status}
          />
          <MultiselectDropdown
            label="Legal Basis For Processing Contact's Data"
            options={sortObjectsByAttribute(contactLegalBasis ? contactLegalBasis : [], 'displayOrder')}
            onChange={(value) => { setFieldValue('hs_legal_basis', value) }}
            value={values.hs_legal_basis}
            placeholder={'Search'}
          />
          <div>
            <FloatingLabel label='Record Source' className='custom-select'>
              <Form.Select
                aria-label="Record Source"
                name='hs_object_source'
                {...getFieldProps("hs_object_source")}
                disabled={true}
              >
                <option value="" key="0">--</option>
                {hsObjectSource?.map((item) => {
                  return <option value={item.id} key={item.id}>{item.name}</option>
                })}
              </Form.Select>
            </FloatingLabel>
          </div>
          <div className='border-bottom'></div>
          <ContactAssociation
            id={contactData?.id}
            section={'contacts'}
            type={'contact_to_contact'}
            associate_to={'contacts'}
            associatedContacts={contactAssociations ? contactAssociations : []}
            associatedContactIds={extractIds(contactAssociations ? contactAssociations : [])}
            fetchData={getSingleContactData}
            sendMessage={sendMessage}
            handleRemove={contactAssociationRemove}
            showLoader={showLoader}
            setShowLoader={setShowLoader}
            onContactPreviewClick={onContactPreviewButtonClick}
          />
          <div className='border-bottom'></div>
          <CompanyAssociation
            id={contactData?.id}
            section={'contacts'}
            type={'contact_to_company'}
            associate_to={'companies'}
            associatedCompanies={companyAssociations ? companyAssociations : []}
            associatedCompanyIds={extractIds(companyAssociations ? companyAssociations : [])}
            fetchData={getSingleContactData}
            sendMessage={sendMessage}
            primaryKey={contactData?.properties?.associatedcompanyid}
            handleRemove={companyAssociationRemove}
            handleSetPrimary={(e, data, pk, type, section, associate_to) => { return handleSetPrimary(e, data, pk, type, section, associate_to, contactData) }}
            handleRemovePrimary={handleRemovePrimary}
            showLoader={showLoader}
            setShowLoader={setShowLoader}
            primaryCompanyName={`${contactData?.properties?.primary_company_name ? contactData?.properties?.primary_company_name : ""}`.trim()}
            name={getName(contactData)}
            handleRemovePrimaryAssociation={handleRemovePrimaryAssociation}
            setContactData={setContactData}
            setCompanyAssociations={setCompanyAssociations}
            onCompanyPreviewClick={onCompanyPreviewClick}
          />
          <div className='border-bottom'></div>
          <DealAssociation
            id={contactData?.id}
            section={'contacts'}
            type={'contact_to_deal'}
            associate_to={'deals'}
            associatedDeals={dealAssociations ? dealAssociations : []}
            associatedDealIds={extractIds(dealAssociations ? dealAssociations : [])}
            fetchData={getSingleContactData}
            sendMessage={sendMessage}
            handleRemove={dealAssociationRemove}
            primaryKey={contactData?.properties?.associatedcompanyid}
            name={`${contactData?.properties?.primary_company_name ? contactData?.properties?.primary_company_name : ""}`.trim()}
            showLoader={showLoader}
            setShowLoader={setShowLoader}
            onDealPreviewClick={onDealPreviewClick}
          />
          <div className='d-flex align-items-center justify-content-center'>
            <div>
              <Button className="mx-4 cclBtn" onClick={closeButtonHandler}>Cancel</Button>
              <Button disabled={isSaving} type="button" onClick={handleSubmit}>Save {isSaving && <Spin className="ms-2 text-white" indicator={<LoadingOutlined style={{ fontSize: '18px', fill: '#fff' }} spin />} />}</Button>
            </div>
            <a className="mx-4 view-record-link" onClick={(e) => { viewRecord(e, contactData) }}>View record</a>
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export { PreviewButton, ContactOffCanvas };