/* eslint-disable eqeqeq */
import '../../SCSS/editTooltip.scss';
import { MdEdit } from "react-icons/md";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import '../../SCSS/popups.scss';
import "../../SCSS/ContactPage.scss"
import Button from '../../commonModules/UI/Button';
import React, { useEffect, useState } from 'react';
import { Popover, Space, Spin } from 'antd';
import { useFormik } from 'formik';
import { LoadingOutlined } from '@ant-design/icons';
import { updateHSCompany } from '../../../API/authCurd';
import PhoneNumberPopover from './PhoneNumberPopover';
import { hsCompanyUpdateSchema } from '../../../Utils/validation';
import { ErrorMessage } from '../../hooks/hubspot';

const initialValues = {
  name: '',
  domain: '',
  phone: '',
  website: '',
  is_company_phone_formatted: false
}

const EditForm = ({ errors, getFieldProps, handleSubmit, touched, values, onPhoneApplyClick, isSaving, onCancel }) => {
  return (<div className='edit-info-pop'>
    <div className='mb-3'>
      <FloatingLabel label="Company Name">
        <Form.Control
          type="text"
          name='name'
          placeholder='Company Name'
          {...getFieldProps("name")}
        />
      </FloatingLabel>
      {(touched.name && errors.name) ? <span className='ms-2 text-danger'>{errors.name}</span> : null}
    </div>
    <div className='mb-3'>
      <FloatingLabel label="Domain">
        <Form.Control
          type="text"
          placeholder='Domain'
          name='domain'
          {...getFieldProps("domain")}
        />
      </FloatingLabel>
      {(touched.domain && errors.domain) ? <span className='ms-2 text-danger'>{errors.domain}</span> : null}
    </div>
    <div className='mb-3'>
      <FloatingLabel label="Website">
        <Form.Control
          type="text"
          placeholder='website'
          name='website'
          {...getFieldProps("website")}
        />
      </FloatingLabel>
      {(touched.website && errors.website) ? <span className='ms-2 text-danger'>{errors.website}</span> : null}
    </div>
    <div>
      <PhoneNumberPopover
        type="text"
        name='phone'
        onApplyClick={onPhoneApplyClick}
        value={values.phone ?? ""}
        placeholder={'Phone Number'}
        placement={'right'}
        isFormatted={values?.is_company_phone_formatted ? true : false}
      />
    </div>
    <div className='d-flex align-items-center justify-content-center gap-3 mt-3'>
      <Button className="cclBtn" onClick={onCancel}>Cancel</Button>
      <Button type="button" disabled={isSaving} onClick={handleSubmit}>Save {isSaving && <Spin className="ms-2 text-white" indicator={<LoadingOutlined style={{ fontSize: '18px', fill: '#fff' }} spin />} />}</Button>
    </div>
  </div>
  )
};

const EditTooltip = ({ record, id, onUpdate, sendMessage }) => {
  const [open, setOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false)

  const { errors, getFieldProps, handleSubmit, touched, setFieldValue, values, resetForm } = useFormik({
    initialValues,
    validationSchema: hsCompanyUpdateSchema,
    onSubmit: (values) => {
      let data = { ...values }
      setIsSaving(true)
      updateHSCompany(id, data).then((res) => {
        if(res.data?.original?.code == '403'){
          let message = res.data?.original?.error || ""
          sendMessage({ type: 'error', content: <ErrorMessage heading={'Permission denied'} message={message} /> }) 
          setIsSaving(false)
        } else {
        onUpdate(true).finally(() => {
          setOpen(false)
          setIsSaving(false)
        })
      }
      }).catch((err) => { setIsSaving(false) })
    },
  });

  const onPhoneApplyClick = (val, isFormatted) => {
    setFieldValue('is_company_phone_formatted', isFormatted)
    setFieldValue('phone', val)
  }

  useEffect(() => {
    if (record && open) {
      Object.keys(initialValues).forEach((field) => {
        if(field == 'is_company_phone_formatted'){
          setFieldValue(field, record?.properties?.[field] == 'true' ? true : false)
        } else{
          setFieldValue(field, record?.properties?.[field] ? record?.properties?.[field] : "")
        }
      })
    }
  }, [record, setFieldValue, open])

  const onUpdateHandler = (load = false) => {
    setOpen(false)
    resetForm()
    onUpdate(load)
  }

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  return (<Space wrap>
    <Popover
      placement='left'
      content={<EditForm
        errors={errors}
        getFieldProps={getFieldProps}
        handleSubmit={handleSubmit}
        touched={touched}
        values={values}
        onCancel={onUpdateHandler}
        onPhoneApplyClick={onPhoneApplyClick}
        isSaving={isSaving}
      />}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
    >
      <MdEdit className='edit-info-icon' />
    </Popover>
  </Space>
  )
}
export default EditTooltip;