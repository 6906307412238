/* eslint-disable eqeqeq */
import { useEffect, useState } from "react";
import { fetchCompanyIndustry, fetchCompanyType, fetchCompanyOwners, fetchCompanyList, fetchCompanyProps } from "../../API/authCurd";

const useCompanyOwners = ({ initialLoad = false } = {}) => {
    const [companyOwners, setCompanyOwners] = useState([]);

    async function getCompanyOwners() {
        return fetchCompanyOwners().then((res) => {
            let data = res?.data ? res?.data : []
            setCompanyOwners(data)
            return Promise.resolve(data)
        }).catch((err) => {
            setCompanyOwners([])
            return Promise.reject(err)
        })
    }

    useEffect(() => {
        if (initialLoad) {
            getCompanyOwners();
        }
    }, [initialLoad]);

    return { companyOwners, setCompanyOwners, getCompanyOwners };
}

const useCompanyType = ({ initialLoad = false } = {}) => {
    const [companyType, setCompanyType] = useState([]);

    async function getCompanyType() {
        return fetchCompanyType().then((res) => {
            let data = res?.data ? res?.data : []
            setCompanyType(data)
            return Promise.resolve(data)
        }).catch((err) => {
            setCompanyType([])
            return Promise.reject(err)
        })
    }

    useEffect(() => {
        if (initialLoad) {
            getCompanyType();
        }
    }, [initialLoad]);

    return { companyType, setCompanyType, getCompanyType };
}

const useCompanyIndustry = ({ initialLoad = false } = {}) => {
    const [companyIndustry, setCompanyIndustry] = useState([]);

    async function getCompanyIndustry() {
        return fetchCompanyIndustry().then((res) => {
            let data = res?.data ? res?.data : []
            setCompanyIndustry(data)
            return Promise.resolve(data)
        }).catch((err) => {
            setCompanyIndustry([])
            return Promise.reject(err)
        })
    }

    useEffect(() => {
        if (initialLoad) {
            getCompanyIndustry();
        }
    }, [initialLoad]);

    return { companyIndustry, setCompanyIndustry, getCompanyIndustry };
}

const useCompanyList = ({ initialLoad = false } = {}) => {
    const [companies, setCompanies] = useState([]);
    const [companiesLoading, setCompaniesLoading] = useState(false);

    async function getCompanyList() {
        setCompaniesLoading(true)
        return fetchCompanyList().then((res) => {
            let data = res?.data ? res?.data : []
            let newdata = data?.map(({ id, name, domain }) => {
                let label = name ? name : domain
                return {
                    label: label ? label : '--',
                    value: id
                }
            })
            setCompanies(newdata)
            setCompaniesLoading(false)
            return Promise.resolve(data)
        }).catch((err) => {
            setCompanies([])
            setCompaniesLoading(false)
            return Promise.reject(err)
        })
    }

    useEffect(() => {
        if (initialLoad) {
            getCompanyList();
        }
    }, [initialLoad]);

    return { companies, setCompanies, getCompanyList, companiesLoading, setCompaniesLoading };
}

const useCompanyProps = ({ initialLoad = false } = {}) => {
    const [companyProps, setCompanyProps] = useState([]);
    const [companyOriginalSource, setCompanyOriginalSource] = useState(null);
    const [loadingCompanyProps, setLoadingCompanyProps] = useState(false);

    async function getCompanyProps(loader = false) {
        if (loader) { setLoadingCompanyProps(true) }
        return fetchCompanyProps().then((res) => {
            let data = res?.data ? res?.data : []
            let originalSources = data.find((item) => item.name == 'hs_analytics_source')
            setCompanyProps(data)
            setCompanyOriginalSource(originalSources?.options ? originalSources?.options : [])
            setLoadingCompanyProps(false)
            return Promise.resolve(data)
        }).catch((err) => {
            setCompanyProps([])
            setLoadingCompanyProps(false)
            return Promise.reject(err)
        })
    }

    useEffect(() => {
        if (initialLoad) {
            getCompanyProps();
        }
    }, [initialLoad]);

    return { companyProps, setCompanyProps, getCompanyProps, loadingCompanyProps, setLoadingCompanyProps, companyOriginalSource, setCompanyOriginalSource };
}

export { useCompanyType, useCompanyOwners, useCompanyIndustry, useCompanyList, useCompanyProps };
