/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import Button from '../../Button'
import { Empty, Radio, Spin, Tooltip, Button as AntBtn } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import { Form } from 'react-bootstrap';
import { sortObjectsByAttribute } from '../../../../../Utils/sortFunctions';
import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { getValidValues } from '../../../../../Utils/helpers';
import CheckboxLabel from '../../CheckboxLabel';
import { editAssociation, editPrimaryCompany, editPrimaryCompanyOfDeal } from '../../../../../API/authCurd';
import { EditMessage } from '../../../../hooks/hubspot';

const totalSteps = 2

const ExistingCompanies = ({ loader, companies, id, type, section, associate_to, fetchData, closeHandler, sendMessage, associatedIds, setShowLoader, primaryKey, primaryCompanyName, name, setContactData, setCompanyAssociations }) => {
    const [step, setStep] = useState(1)
    const [values, setValues] = useState([])
    const [searchQuery, setSearchQuery] = useState('');
    const [searchedItems, setSearhcedItems] = useState([])
    const [primaryCompany, setPrimaryCompany] = useState(null);
    const [isSaving, setIsSaving] = useState(false)

    useEffect(() => {
        if (!primaryKey && values?.length > 0 && section == 'contacts') {
            setPrimaryCompany(values?.[0]?.value ? values?.[0]?.value : null)
        }
    }, [primaryKey, values, section])

    const addAssociation = () => {
        let payload = {}
        payload.from = id
        payload.to = getValidValues(values)
        payload.type = (section == 'contacts' && associate_to == 'companies') ? 279 : type
        if (section != 'companies') {
            payload.primaryCompany = primaryCompany
        }
        payload.section = section
        payload.associateTo = associate_to
        if (payload?.type == 'deal_to_company') {
            payload.type = 341
        }
        setIsSaving(true)
        editAssociation(payload).then((res) => {
            if (primaryCompany && section != 'companies') {
                if (section == 'contacts') {
                    let data = {
                        contact_id: id,
                        company_id: primaryCompany
                    }
                    setShowLoader((prev) => ({ ...prev, [associate_to]: true }))
                    editPrimaryCompany(data).then(() => {
                        sendMessage({ content: <EditMessage msg={'Your association were updated.'} /> })
                        fetchData(id).then(() => {
                            let primaryCompanyData = companies?.find((item) => { return item?.value == primaryCompany })
                            setContactData((prev) => ({
                                ...prev, properties: {
                                    ...prev.properties,
                                    associatedcompanyid: primaryCompanyData?.value ? primaryCompanyData?.value : '',
                                    primary_company_name: primaryCompanyData?.label ? primaryCompanyData?.label : ''
                                }
                            }))
                            setCompanyAssociations((prev) => {
                                const index = prev?.findIndex(item => item?.id == primaryCompany);
                                if (index > -1) {
                                  const [matchedItem] = prev.splice(index, 1);
                                  return [matchedItem, ...prev];
                                }
                                return prev;
                              });
                        })
                        closeHandler()
                    }).finally(() => {
                        setIsSaving(false)
                    })
                } else if (section == 'deals') {
                    let data = {
                        from: id,
                        to: primaryCompany,
                        type: 5,
                        associateTo: associate_to
                    }
                    setShowLoader((prev) => ({ ...prev, [associate_to]: true }))
                    editPrimaryCompanyOfDeal(data).then(() => {
                        sendMessage({ content: <EditMessage msg={'Your association were updated.'} /> })
                        fetchData(id)
                        closeHandler()
                    }).finally(() => {
                        setIsSaving(false)
                    })
                }
            } else {
                sendMessage({ content: <EditMessage msg={'Your association were updated.'} /> })
                setShowLoader((prev) => ({ ...prev, [associate_to]: true }))
                fetchData(id)
                closeHandler()
            }
        }).catch(() => { setIsSaving(false) })
    }

    const nextBtnHandle = (e) => {
        setStep((prev) => { return prev + 1 })
    }

    const prevBtnHandle = (e) => {
        setStep((prev) => { return prev == 0 ? 0 : prev - 1 })
    }

    const saveButtonClick = (e) => {
        addAssociation()
    }

    const checkBoxChangeHandler = (e, item) => {
        let comps = [...values];
        const itemIndex = comps.findIndex((comp) => comp.value == item.value);

        if (itemIndex !== -1) {
            comps.splice(itemIndex, 1);
        } else {
            comps.push(item);
        }

        setValues(comps);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        if (searchQuery) {
            const filteredItems = companies?.filter((item) =>
                item?.label?.toLowerCase()?.includes(searchQuery?.toLowerCase())
            )
            setSearhcedItems(filteredItems ? sortObjectsByAttribute(filteredItems, 'label') : [])
        } else {
            setSearhcedItems(companies ? sortObjectsByAttribute(companies, 'label') : [])
        }
    }, [searchQuery, companies])

    const handlePrimaryChange = (companyId) => {
        setPrimaryCompany(companyId);
    };

    const removeCompany = (compid) => {
        setValues((prev) => {
            const updatedValues = prev.filter(item => item.value != compid);

            if (updatedValues.length === 0) {
                setStep(1);
            }

            return updatedValues;
        })
    }

    return (
        <div className='existing-form'>
            <div className='form-wrapper'>
                {step == 1 && <div className='step-one'>
                    <input
                        placeholder={'Search companies'}
                        type="text"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        className='search-box'
                    />
                    {values?.length > 0 && <p>{values?.length} selected</p>}
                    <div className='ListHieght association-list'>
                        {loader ? <div className='list-spinner'>
                            <Spin className="ms-2 text-white" indicator={<LoadingOutlined style={{ fontSize: 28, fill: '#fff', color: "#2c0036" }} spin />} />
                        </div> : <>{searchedItems?.length == 0 ?
                            <Empty className='empty-data' image={Empty.PRESENTED_IMAGE_SIMPLE} /> : searchedItems.map((item) => (
                                <Form.Check
                                    key={item.value}
                                    type="checkbox"
                                    // label={`${item?.label ? item?.label : ""}`}
                                    label={<CheckboxLabel label={`${item?.label ? item?.label : ""}`} isAlreadyAssociated={associatedIds?.includes(item.value)} />}
                                    checked={values.some((comp) => comp.value == item.value) || associatedIds?.includes(item.value)}
                                    onChange={(e) => checkBoxChangeHandler(e, item)}
                                    disabled={associatedIds?.includes(item.value)}
                                />
                            ))}</>}
                    </div>
                </div>}
                {step == 2 && <div className='association-step-two'>
                    {values.map((company) => (
                        <div key={company.value} style={{ marginBottom: 24 }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <h6 className='fontweight'>{company.label ? company.label : '--'}</h6>
                                <AntBtn type="text" icon={<CloseOutlined />} onClick={() => removeCompany(company.value)} />
                            </div>

                            {!(section == 'companies') && <div style={{ marginTop: 8 }}>
                                <Radio
                                    checked={primaryCompany === company.value}
                                    onChange={() => handlePrimaryChange(company.value)}
                                >
                                    {!primaryKey ? name ? `Set as ${name}'s primary company.` : 'Set as primary company.' : name ? `Replace ${primaryCompanyName} as ${name}'s current primary.` : `Replace ${primaryCompanyName} as current primary.`}
                                    {/* Set as primary company. */}
                                    <Tooltip title="Primary company is the company referenced for this contact by lists, workflows, reports, and personalization tokens.">
                                        <InfoCircleOutlined style={{ marginLeft: 4 }} />
                                    </Tooltip>
                                </Radio>
                            </div>}
                        </div>
                    ))}</div>}
            </div>

            <div className='association-buttons'>
                {step > 1 && <Button type="button" className='prev-button' onClick={prevBtnHandle}>Prev</Button>}
                <div className='next-btn'>
                    <p>Step {step} of {totalSteps}</p>
                    {step == totalSteps ? <Button type="button" onClick={saveButtonClick} disabled={isSaving || values?.length == 0}>Save {isSaving && <Spin className="ms-2 text-white" indicator={<LoadingOutlined style={{ fontSize: '18px', fill: '#fff' }} spin />} />}</Button> : <Button type="button" onClick={nextBtnHandle} disabled={values?.length == 0}>Next</Button>}
                </div>
            </div>
        </div>
    )
}

export default ExistingCompanies