/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import { Offcanvas, Tab, Tabs } from 'react-bootstrap'
import { MdAdd } from "react-icons/md";
import ExistingCompanies from './ExistingCompanies';
import { useCompanyList } from '../../../../hooks/company';
import CompanyCard from './CompanyCard';
import CreateCompanyForm from './CreateCompanyForm';
import { Drawer, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import { editPrimaryCompany, editPrimaryCompanyOfDeal, removeAssociation } from '../../../../../API/authCurd';

const CompanyAssociation = ({ associatedCompanies = [], associatedCompanyIds = [], id, type, section, associate_to, fetchData, sendMessage, primaryKey, handleRemove, handleSetPrimary, handleRemovePrimary, showLoader, setShowLoader, primaryCompanyName, name, handleRemovePrimaryAssociation, setContactData, setCompanyAssociations, onCompanyPreviewClick }) => {
  const [activeKey, setActiveKey] = useState('add_existing')
  const [show, setShow] = useState(false);
  const { companies, getCompanyList } = useCompanyList();
  const [loader, setLoader] = useState(false)

  const onAddButtonClick = (e,) => {
    e.preventDefault()
    setShow(true)
  }

  const onTabSelect = (key) => {
    if (key == 'create_new') {
    } else if (key == 'add_existing') {
      setLoader(true)
      getCompanyList().finally(() => setLoader(false))
    }
    setActiveKey(key)
  }

  function closeCanavasButtonHandler() {
    setShow(false)
  }

  useEffect(() => {
    setLoader(true)
    getCompanyList().finally(() => setLoader(false))
  }, [])

  const handleRemoveCompany = async (e, data) => {
    const isConfirm = await handleRemove(e, data)
    if (isConfirm) {
      let payload = {
        from_object_type: section,
        to_object_type: associate_to,
        associations: [{
          from_id: id,
          to_id: data?.id,
          type: type
        }]
      }
      setShowLoader((prev) => ({ ...prev, [associate_to]: true }))
      removeAssociation(payload).then((res) => {
        if (section == 'contacts') {
          setCompanyAssociations((prev) => {
            const updatedAssociations = prev?.filter(item => item?.id != data?.id);

            if (updatedAssociations.length === 0) {
              setContactData((prev) => ({
                ...prev,
                properties: {
                  ...prev.properties,
                  associatedcompanyid: "",
                  primary_company_name: ""
                }
              }));
            }

            return updatedAssociations;
          })
          setShowLoader((prev) => ({ ...prev, [associate_to]: false }))
        } else {
          fetchData(id)
        }
      }).catch((err) => {
      })
    }
  }

  const handleRemovePrimaryCompany = async (e, data) => {
    const confirm = await handleRemovePrimary(e, data, primaryKey, associatedCompanies)
    if (confirm?.confirmed) {
      if (section == 'contacts') {
        let data = {
          contact_id: id,
          company_id: confirm?.value
        }
        setShowLoader((prev) => ({ ...prev, [associate_to]: true }))
        editPrimaryCompany(data).then(() => {
          let newCompany = associatedCompanies?.find(item => item.id == confirm?.value)
          setContactData((prev) => ({
            ...prev, properties: {
              ...prev.properties,
              associatedcompanyid: confirm?.value,
              primary_company_name: getNameOrDomain(newCompany)
            }
          }))
          setCompanyAssociations((prev) => {
            const index = prev?.findIndex(item => item?.id == confirm?.value);
            if (index > -1) {
              const [matchedItem] = prev.splice(index, 1);
              return [matchedItem, ...prev];
            }
            return prev;
          });
          setShowLoader((prev) => ({ ...prev, [associate_to]: false }))
          // fetchData(id)
        })
      } else if (section == 'deals') {
        let payload = {
          from: id,
          to: confirm?.value ? confirm?.value : primaryKey,
          type: confirm?.value ? 5 : 341,
          associateTo: associate_to
        }
        setShowLoader((prev) => ({ ...prev, [associate_to]: true }))
        editPrimaryCompanyOfDeal(payload).then(() => {
          fetchData(id)
        })
      }
    }
  }

  const getNameOrDomain = (data) => {
    let name = `${data?.properties?.name ? data?.properties?.name : ""}`.trim()
    return name ? name : data?.properties?.domain ? data?.properties?.domain : '--'
  }

  const removePrimaryCompanyAssociation = async (e, data) => {
    const confirm = await handleRemovePrimaryAssociation(e, data, primaryKey, associatedCompanies)
    if (confirm?.confirmed) {
      if (section == 'contacts') {
        let payload = {
          from_object_type: section,
          to_object_type: associate_to,
          associations: [{
            from_id: id,
            to_id: data?.id,
            type: type
          }]
        }
        setShowLoader((prev) => ({ ...prev, [associate_to]: true }))
        removeAssociation(payload).then((res) => {
          let newCompany = associatedCompanies?.find(item => item.id == confirm?.value)
          let postdata = {
            contact_id: id,
            company_id: confirm?.value
          }
          editPrimaryCompany(postdata).then(() => {
            setContactData((prev) => ({
              ...prev, properties: {
                ...prev.properties,
                associatedcompanyid: confirm?.value,
                primary_company_name: getNameOrDomain(newCompany)
              }
            }))
            setCompanyAssociations((prev) => {
              const filteredItems = prev?.filter(item => item?.id !== data?.id);
              const index = filteredItems?.findIndex(item => item?.id === confirm?.value);
              if (index > -1) {
                const [matchedItem] = filteredItems.splice(index, 1);
                return [matchedItem, ...filteredItems];
              }
              return filteredItems;
            })

            setShowLoader((prev) => ({ ...prev, [associate_to]: false }))
            // fetchData(id)
          })
        })
      } else if (section == 'deals') {
        let payload = {
          from_object_type: section,
          to_object_type: associate_to,
          associations: [{
            from_id: id,
            to_id: data?.id,
            type: type
          }]
        }
        setShowLoader((prev) => ({ ...prev, [associate_to]: true }))
        removeAssociation(payload).then((res) => {
          let data = {
            from: id,
            to: confirm?.value ? confirm?.value : primaryKey,
            type: confirm?.value ? 5 : 341,
            associateTo: associate_to
          }
          editPrimaryCompanyOfDeal(data).then(() => {
            fetchData(id)
          })
        })
      }
    }
  }

  const handleSetPrimaryButton = async (e, data, pk) => {
    let isConfirm = await handleSetPrimary(e, data, pk, type, section, associate_to)
    if (isConfirm) {
      if (section == 'contacts') {
        let payload = {
          contact_id: id,
          company_id: data.id
        }
        setShowLoader((prev) => ({ ...prev, [associate_to]: true }))
        editPrimaryCompany(payload).then(() => {
          setContactData((prev) => ({
            ...prev, properties: {
              ...prev.properties,
              associatedcompanyid: data.id,
              primary_company_name: getNameOrDomain(data)
            }
          }))
          setCompanyAssociations((prev) => {
            const index = prev?.findIndex(item => item?.id == data?.id);
            if (index > -1) {
              const [matchedItem] = prev.splice(index, 1);
              return [matchedItem, ...prev];
            }
            return prev;
          });
          setShowLoader((prev) => ({ ...prev, [associate_to]: false }))
          // fetchData(id)
        })
      } else if (section == 'deals') {
        let payload = {
          from: id,
          to: data.id,
          type: 5,
          associateTo: associate_to
        }
        setShowLoader((prev) => ({ ...prev, [associate_to]: true }))
        editPrimaryCompanyOfDeal(payload).then(() => {
          fetchData(id)
        })
      }
    }
  }

  const onCloseCreateForm = () => {
    setShowLoader((prev) => ({ ...prev, [associate_to]: true }))
    fetchData(id)
    setShow(false)
  }

  return (
    <>
      <div className='association-wrapper'>
        <div className='association-heading mb-2'>
          <h6>
            {showLoader[associate_to] && <Spin className="me-2" indicator={<LoadingOutlined style={{ fontSize: '18px', fill: '#2c0036', color: '#2c0036' }} spin />} />}
            Companies {`(${associatedCompanies?.length > 0 ? associatedCompanies?.length : 0})`}
          </h6>
          <a className="mx-4 view-record-link" onClick={(e) => { onAddButtonClick(e) }}><MdAdd />Add</a>
        </div>
        <div className='association-body'>
          {associatedCompanies?.length > 0 ? <>
            {associatedCompanies?.map((item) => {
              return <CompanyCard
                key={item.id}
                companyData={item}
                primaryKey={primaryKey}
                handleRemove={handleRemoveCompany}
                handleSetPrimary={handleSetPrimaryButton}
                section={section}
                handleRemovePrimaryCompany={handleRemovePrimaryCompany}
                removePrimaryCompanyAssociation={removePrimaryCompanyAssociation}
                associatedCompanies={associatedCompanies}
                onCompanyPreviewClick={onCompanyPreviewClick}
              />
            })}
          </> : <p>See the businesses or organizations associated with this record.</p>}
        </div>
      </div>
      <Drawer placement='right' title={
        <div className='drawer-title'>
          {activeKey == 'create_new' ? 'Create company' : 'Add existing company'}
        </div>
      }
        destroyOnClose={true}
        open={show}
        onClose={closeCanavasButtonHandler}
        className="existingContact"
      >
        <div className='drawer-content-wrapper'>
          <Tabs
            defaultActiveKey="add_existing"
            className="mb-3"
            activeKey={activeKey}
            onSelect={onTabSelect}
            fill
          >
            <Tab eventKey="create_new" title="Create new">
              <CreateCompanyForm
                sendMessage={sendMessage}
                onClose={() => { onCloseCreateForm() }}
                contactId={id}
                section={section}
                id={id}
                associate_to={associate_to}
                type={type}
              />
            </Tab>
            <Tab eventKey="add_existing" title="Add Existing" className='list-overflow'>
              <ExistingCompanies
                companies={companies ? companies.filter(company => {
                  if (section === 'companies') { return company?.value != id; }
                  return true;
                }) : []}
                activeKey={activeKey}
                loader={loader}
                id={id}
                type={type}
                section={section}
                associate_to={associate_to}
                fetchData={fetchData}
                closeHandler={closeCanavasButtonHandler}
                sendMessage={sendMessage}
                associatedIds={associatedCompanyIds}
                setShowLoader={setShowLoader}
                primaryKey={primaryKey}
                primaryCompanyName={primaryCompanyName}
                name={name}
                setContactData={setContactData}
                setCompanyAssociations={setCompanyAssociations}
              />
            </Tab>
          </Tabs>
        </div>
      </Drawer>
    </>
  )
}

export default CompanyAssociation