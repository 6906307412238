/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import { Offcanvas, Tab, Tabs } from 'react-bootstrap'
import { MdAdd } from "react-icons/md";
import { useDealList, useDealsPipelines } from '../../../../hooks/deals';
import ExistingDeal from './ExistingDeal';
import DealCard from './DealCard';
import CreateDealForm from './CreateDealForm';
import { removeAssociation } from '../../../../../API/authCurd';
import { Drawer, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';

const DealAssociation = ({ associatedDeals = [], associatedDealIds = [], id, type, section, associate_to, fetchData, sendMessage, handleRemove, primaryKey, name, showLoader, setShowLoader, onDealPreviewClick }) => {
  const [activeKey, setActiveKey] = useState('create_new')
  const [show, setShow] = useState(false);
  const { deals, getDeals } = useDealList();
  const [loader, setLoader] = useState(false)
  const { dealsPipelines, getDealsPipelines } = useDealsPipelines();

  const onAddButtonClick = (e,) => {
    e.preventDefault()
    setShow(true)
  }

  const onTabSelect = (key) => {
    if (key == 'create_new') {
    } else if (key == 'add_existing') {
      setLoader(true)
      getDeals().finally(() => setLoader(false))
    }
    setActiveKey(key)
  }

  function closeCanavasButtonHandler() {
    setShow(false)
  }

  useEffect(() => {
    setLoader(true)
    getDealsPipelines()
    getDeals().finally(() => setLoader(false))
  }, [])

  const handleRemoveDeal = async (e, data) => {
    const isConfirm = await handleRemove(e, data)
    if (isConfirm) {
      let payload = {
        from_object_type: section,
        to_object_type: associate_to,
        associations: [{
          from_id: id,
          to_id: data?.id,
          type: type
        }]
      }
      setShowLoader((prev) => ({ ...prev, [associate_to]: true }))
      removeAssociation(payload).then((res) => {
        fetchData(id)
      }).catch((err) => {
      })
    }
  }

  const onCloseCreateForm = () => {
    setShowLoader((prev) => ({ ...prev, [associate_to]: true }))
    fetchData(id)
    setShow(false)
  }

  return (
    <>
      <div className='association-wrapper'>
        <div className='association-heading mb-2'>
          <h6>
            {showLoader[associate_to] && <Spin className="me-2" indicator={<LoadingOutlined style={{ fontSize: '18px', fill: '#2c0036', color: '#2c0036' }} spin />} />}
            Deals {`(${associatedDeals?.length > 0 ? associatedDeals?.length : 0})`}
          </h6>
          <a className="mx-4 view-record-link" onClick={(e) => { onAddButtonClick(e) }}><MdAdd />Add</a>
        </div>
        <div className='association-body'>
          {associatedDeals?.length > 0 ? <>{associatedDeals?.map((item) => {
            return <DealCard key={item.id} dealData={item} dealsPipelines={dealsPipelines} fetchData={fetchData} sendMessage={sendMessage} handleRemove={handleRemoveDeal} setShowLoader={setShowLoader} associate_to={associate_to} fetchId={id} onDealPreviewClick={onDealPreviewClick} />
          })}</> : <p>Track the revenue opportunities associated with this record.</p>}
        </div>
      </div>
      <Drawer placement='right' title={
        <div className='drawer-title'>
          {activeKey == 'create_new' ? 'Create deal' : 'Add existing deal'}
        </div>
      }
        destroyOnClose={true}
        open={show}
        onClose={closeCanavasButtonHandler}
        className="existingContact"
      >
        <div className='drawer-content-wrapper'>
          <Tabs
            defaultActiveKey="create_new"
            className="mb-3"
            activeKey={activeKey}
            onSelect={onTabSelect}
            fill
          >
            <Tab eventKey="create_new" title="Create new">
              <CreateDealForm
                sendMessage={sendMessage}
                onClose={() => { onCloseCreateForm() }}
                section={section}
                contactId={id}
                companyPrimaryKey={primaryKey}
                name={name}
                id={id}
                associate_to={associate_to}
                type={type}
              />
            </Tab>
            <Tab eventKey="add_existing" title="Add Existing" className='list-overflow'>
              <ExistingDeal
                deals={deals ? deals.filter(deal => {
                  if (section === 'deals') { return deal?.value != id; }
                  return true;
                }) : []}
                activeKey={activeKey}
                loader={loader}
                id={id}
                type={type}
                section={section}
                associate_to={associate_to}
                fetchData={fetchData}
                closeHandler={closeCanavasButtonHandler}
                sendMessage={sendMessage}
                associatedIds={associatedDealIds}
                setShowLoader={setShowLoader}
              />
            </Tab>
          </Tabs>
        </div>
      </Drawer>
    </>
  )
}

export default DealAssociation