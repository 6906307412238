/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import MultiselectDropdown from '../../MultiselectDropdown'
import { sortObjectsByAttribute } from '../../../../../Utils/sortFunctions'
import SearchDropdown from '../../SearchDropdown'
import { FloatingLabel, Form } from 'react-bootstrap'
import Button from '../../Button'
import MyTooltip from '../../MyTooltip'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import { useCompanyProps, useCompanyType } from '../../../../hooks/company'
import { useContactList } from '../../../../hooks/contacts'
import { useDealList } from '../../../../hooks/deals'
import { useFormik } from 'formik'
import { hsCompanySchema } from '../../../../../Utils/validation'
import { addHSCompany, editAssociation } from '../../../../../API/authCurd'
import { EditMessage, ErrorMessage } from '../../../../hooks/hubspot'

const initialValues = {
    name: '',
    domain: '',
    type: '',
    city: '',
    state: '',
    hs_analytics_source: '',
    associated_deals: [],
    associated_contacts: []
}

const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,7}$/;

const CreateCompanyForm = ({ sendMessage, onClose, contactId, section, id, associate_to, type }) => {
    const [isSaving, setIsSaving] = useState(false)
    const { companyType, getCompanyType } = useCompanyType();
    const { companyOriginalSource, getCompanyProps } = useCompanyProps();
    const [areFieldsEnabled, setAreFieldsEnabled] = useState(false);
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const { contacts, getContacts, contactsLoading } = useContactList();
    const { deals, getDeals, dealsLoading } = useDealList();

    const { errors, resetForm, getFieldProps, handleSubmit, touched, values, setFieldValue } = useFormik({
        initialValues,
        validationSchema: hsCompanySchema,
        onSubmit: (values) => {
            const data = { ...values }
            // if (data.hubspot_owner_id == 'default') { data.hubspot_owner_id = "" }
            setIsSaving(true)
            let formattedData = {
                companyData: {
                    name: data?.name || "",
                    domain: data?.domain || "",
                    type: data?.type || "",
                    city: data?.city || "",
                    state: data?.state || "",
                    hs_analytics_source: data?.hs_analytics_source || "",
                },
                contactIds: {
                    id: data?.associated_contacts ? data?.associated_contacts : [],
                    type: "company_to_contact"
                },
                dealIds: {
                    id: data?.associated_deals ? data?.associated_deals : [],
                    type: "company_to_deal"
                }
            }
            addHSCompany(formattedData).then((res) => {
                if (res.data?.original?.code == '403') {
                    let message = res.data?.original?.error || ""
                    sendMessage({ type: 'error', content: <ErrorMessage heading={'Permission denied'} message={message} /> })
                    setIsSaving(false)
                } else {
                if (section == 'companies') {
                    let payload = {}
                    payload.from = id
                    payload.to = res?.data?.company?.id ? [res?.data?.company?.id] : []
                    payload.type = type
                    payload.section = section
                    payload.associateTo = associate_to
                    setIsSaving(true)
                    editAssociation(payload).then((res) => {
                        sendMessage({ content: <EditMessage msg={'Your company was created.'} /> })
                        closeButtonHandler()
                    }).finally(() => { setIsSaving(false) })
                } else {
                    sendMessage({ content: <EditMessage msg={'Your company was created.'} /> })
                    closeButtonHandler()
                    setIsSaving(false)
                }
            }
            }).catch((err)=>{ })
        },
    });

    function showButtonHandler() {
        getCompanyType()
        getContacts()
        getDeals()
        getCompanyProps()
    }

    useEffect(() => {
        if (contactId) {
            if (section == 'deals') {
                setFieldValue('associated_deals', [contactId])
            } else {
                if (section != 'companies') {
                    setFieldValue('associated_contacts', [contactId])
                }
            }
        }
    }, [contactId, section])

    useEffect(()=>{
        showButtonHandler()
    }, [])

    function closeButtonHandler() {
        setIsSaving(false)
        onClose()
        resetForm()
    }

    useEffect(() => {
        const isDomainValid = domainRegex.test(values.domain);
        const isPrimaryFieldValid = isDomainValid || values.name;

        setAreFieldsEnabled(!!isPrimaryFieldValid);
    }, [values.domain, values.name]);

    useEffect(() => {
        const isDomainValid = domainRegex.test(values.domain);

        const isNameValid = values.name.trim() !== '';
        const isDomainValidOrEmpty = values.domain.trim() === '' || isDomainValid;

        setIsSaveEnabled((isNameValid || isDomainValid) && isDomainValidOrEmpty);
    }, [values.domain, values.name]);

    return (
        <div className='form-wrap'>
            <div>
                <FloatingLabel label="Company Domain Name">
                    <Form.Control
                        type="text"
                        name='domain'
                        placeholder=''
                        {...getFieldProps("domain")}
                    />
                </FloatingLabel>
                {(touched.domain && errors.domain) ? <span className='ms-2 text-danger'>{errors.domain}</span> : null}
            </div>
            <div>
                <FloatingLabel label="Company Name">
                    <Form.Control
                        type="text"
                        name='name'
                        placeholder=''
                        {...getFieldProps("name")}
                    />
                </FloatingLabel>
                {(touched.name && errors.name) ? <span className='ms-2 text-danger'>{errors.name}</span> : null}
            </div>
            <SearchDropdown
                label="Type"
                options={[
                    { label: "Select a type", value: "" },
                    ...sortObjectsByAttribute(companyType, 'displayOrder')
                ]}
                // placeholder="Choose..."
                placeholder="Select a type"
                onSelect={(value) => setFieldValue('type', value)}
                initialValue={values?.type ?? "default"}
                disabled={!areFieldsEnabled}
            />
            <div>
                <FloatingLabel label="City">
                    <Form.Control
                        type="text"
                        name='city'
                        placeholder=''
                        {...getFieldProps("city")}
                        disabled={!areFieldsEnabled}
                    />
                </FloatingLabel>
                {(touched.city && errors.city) ? <span className='ms-2 text-danger'>{errors.city}</span> : null}
            </div>
            <div>
                <FloatingLabel label="State/Region">
                    <Form.Control
                        type="text"
                        name='state'
                        placeholder=''
                        {...getFieldProps("state")}
                        disabled={!areFieldsEnabled}
                    />
                </FloatingLabel>
                {(touched.state && errors.state) ? <span className='ms-2 text-danger'>{errors.state}</span> : null}
            </div>
            <SearchDropdown
                label="Original source"
                options={[
                    { label: "Select a source", value: "" },
                    ...sortObjectsByAttribute(companyOriginalSource, 'displayOrder')
                ]}
                // placeholder="Choose..."
                placeholder="Select a source"
                onSelect={(value) => setFieldValue('hs_analytics_source', value)}
                initialValue={values?.hs_analytics_source ?? ""}
                disabled={!areFieldsEnabled}
            />
            {/* Company Associations */}
            <div className='border-bottom'></div>

            <h5>Associate company with</h5>

            <div>
                <MultiselectDropdown
                    label="Deal"
                    options={sortObjectsByAttribute(deals ? deals : [], 'label')}
                    onChange={(value) => { setFieldValue('associated_deals', value) }}
                    value={values.associated_deals}
                    disabled={!areFieldsEnabled}
                    placeholder={'Search'}
                    loading={dealsLoading}
                />
            </div>
            <div>
                <MultiselectDropdown
                    label="Contact"
                    options={sortObjectsByAttribute(contacts ? contacts : [], 'label')}
                    onChange={(value) => { setFieldValue('associated_contacts', value) }}
                    value={values.associated_contacts}
                    disabled={!areFieldsEnabled}
                    placeholder={'Search'}
                    loading={contactsLoading}
                />
            </div>
            <div className='d-flex align-items-center justify-content-center'>
                <Button className="mx-4 cclBtn" onClick={closeButtonHandler}>Cancel</Button>
                {values?.domain && !domainRegex.test(values?.domain) ? <MyTooltip title={'Please update invalid property values.'}>
                    <Button type="button" disabled={isSaving || !areFieldsEnabled || !isSaveEnabled} onClick={handleSubmit}>Save {isSaving && <Spin className="ms-2 text-white" indicator={<LoadingOutlined style={{ fontSize: '18px', fill: '#fff' }} spin />} />}</Button>
                </MyTooltip> :
                    <Button type="button" disabled={isSaving || !areFieldsEnabled || !isSaveEnabled} onClick={handleSubmit}>Save {isSaving && <Spin className="ms-2 text-white" indicator={<LoadingOutlined style={{ fontSize: '18px', fill: '#fff' }} spin />} />}</Button>
                }
            </div>
        </div>
    )
}

export default CreateCompanyForm