/* eslint-disable eqeqeq */
import { useEffect, useState } from "react";
import { fetchContactLeadStatus, fetchContactLifecycles, fetchContactOwners, fetchContactProps, fetchContacts } from "../../API/authCurd";

const useContactOwners = ({ initialLoad = false } = {}) => {
    const [contactOwners, setContactOwners] = useState([]);

    async function getContactOwners() {
        return fetchContactOwners().then((res) => {
            let data = res?.data ? res?.data : []
            setContactOwners(data)
            return Promise.resolve(data)
        }).catch((err) => {
            setContactOwners([])
            return Promise.reject(err)
        })
    }

    useEffect(() => {
        if (initialLoad) {
            getContactOwners();
        }
    }, [initialLoad]);

    return { contactOwners, setContactOwners, getContactOwners };
}

const useContactLeadStatus = ({ initialLoad = false } = {}) => {
    const [contactLeadStatus, setContactLeadStatus] = useState([]);

    async function getContactLeadStatus() {
        return fetchContactLeadStatus().then((res) => {
            let data = res?.data ? res?.data : []
            setContactLeadStatus(data)
            return Promise.resolve(data)
        }).catch((err) => {
            setContactLeadStatus([])
            return Promise.reject(err)
        })
    }

    useEffect(() => {
        if (initialLoad) {
            getContactLeadStatus();
        }
    }, [initialLoad]);

    return { contactLeadStatus, setContactLeadStatus, getContactLeadStatus };
}

const useContactLifeCycle = ({ initialLoad = false } = {}) => {
    const [contactLifecycles, setContactLifecycles] = useState([]);

    async function getContactLifecycles() {
        return fetchContactLifecycles().then((res) => {
            let data = res?.data ? res?.data : []
            setContactLifecycles(data)
            return Promise.resolve(data)
        }).catch((err) => {
            setContactLifecycles([])
            return Promise.reject(err)
        })
    }

    useEffect(() => {
        if (initialLoad) {
            getContactLifecycles();
        }
    }, [initialLoad]);

    return { contactLifecycles, setContactLifecycles, getContactLifecycles };
}

const useContactProps = ({ initialLoad = false } = {}) => {
    const [contactProps, setContactProps] = useState([]);
    const [contactLegalBasis, setContactLegalbasis] = useState([]);
    const [contactLifecycles, setContactLifecycles] = useState([]);
    const [contactLeadStatus, setContactLeadStatus] = useState([]);
    const [loadingContactProps, setLoadingContactProps] = useState(false);

    async function getContactProps(loader = false) {
        if (loader) { setLoadingContactProps(true) }
        return fetchContactProps().then((res) => {
            let data = res?.data ? res?.data : []
            let legalBasis = data.find((item) => item.name == 'hs_legal_basis')
            let lifeStage = data.find((item) => item.name == 'lifecyclestage')
            let leadStatus = data.find((item) => item.name == 'hs_lead_status')
            setContactLegalbasis(legalBasis?.options ? legalBasis?.options : [])
            setContactLifecycles(lifeStage?.options ? lifeStage.options : [])
            setContactLeadStatus(leadStatus?.options ? leadStatus?.options : [])
            setContactProps(data)
            setLoadingContactProps(false)
            return Promise.resolve(data)
        }).catch((err) => {
            setContactProps([])
            setLoadingContactProps(false)
            return Promise.reject(err)
        })
    }

    useEffect(() => {
        if (initialLoad) {
            getContactProps();
        }
    }, [initialLoad]);

    return { contactProps, setContactProps, getContactProps, loadingContactProps, setLoadingContactProps, contactLegalBasis, setContactLegalbasis, contactLifecycles, setContactLifecycles, contactLeadStatus, setContactLeadStatus };
}

const useContactList = ({ initialLoad = false } = {}) => {
    const [contacts, setContacts] = useState([]);
    const [contactsLoading, setContactsLoading] = useState(false)

    async function getContacts() {
        setContactsLoading(true)
        return fetchContacts().then((res) => {
            let data = res?.data ? res?.data : []
            let newData = data?.map(({ id, firstname, lastname, email }) => {
                let name = `${firstname || ''} ${lastname || ''}`.trim()
                return {
                    value: id,
                    name: `${name || email || ''}`.trim(),
                    label: `${name || email || ''} ${email ? '(' + email + ')' : ''}`.trim()
                }
            })
            setContacts(newData)
            setContactsLoading(false)
            return Promise.resolve(data)
        }).catch((err) => {
            setContacts([])
            setContactsLoading(false)
            return Promise.reject(err)
        })
    }

    useEffect(() => {
        if (initialLoad) {
            getContacts()
        }
    }, [initialLoad]);

    return { contacts, setContacts, getContacts, contactsLoading, setContactsLoading }
}

export { useContactLifeCycle, useContactOwners, useContactLeadStatus, useContactProps, useContactList };
