/* eslint-disable eqeqeq */
import '../../SCSS/editTooltip.scss';
import { MdEdit } from "react-icons/md";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import '../../SCSS/popups.scss';
import "../../SCSS/ContactPage.scss"
import Button from '../../commonModules/UI/Button';
import React, { useEffect, useState } from 'react';
import { Popover, Space, Spin } from 'antd';
import { useFormik } from 'formik';
import { LoadingOutlined } from '@ant-design/icons';
import { updateHSContact } from '../../../API/authCurd';
import { ErrorMessage } from '../../hooks/hubspot';

const initialValues = {
  firstname: '',
  lastname: '',
  jobtitle: '',
}

const EditForm = ({ isSaving, touched, errors, getFieldProps, handleSubmit, onCancel }) => {
  return (<div className='edit-info-pop'>
    <div className='mb-3'>
      <FloatingLabel label="First Name">
        <Form.Control
          type="text"
          name='firstname'
          placeholder='First Name'
          {...getFieldProps("firstname")}
        />
      </FloatingLabel>
      {(touched.firstname && errors.firstname) ? <span className='ms-2 text-danger'>{errors.firstname}</span> : null}
    </div>
    <div className='mb-3'>
      <FloatingLabel label="Last Name">
        <Form.Control
          type="text"
          placeholder='Last Name'
          name='lastname'
          {...getFieldProps("lastname")}
        />
      </FloatingLabel>
      {(touched.lastname && errors.lastname) ? <span className='ms-2 text-danger'>{errors.lastname}</span> : null}
    </div>
    <div className='mb-3'>
      <FloatingLabel label="Job Title">
        <Form.Control
          type="text"
          name='jobtitle'
          placeholder='Job Title'
          {...getFieldProps("jobtitle")}
        />
      </FloatingLabel>
      {(touched.jobtitle && errors.jobtitle) ? <span className='ms-2 text-danger'>{errors.jobtitle}</span> : null}
    </div>
    <div className='d-flex align-items-center justify-content-center gap-3 mt-3'>
      <Button className="cclBtn" onClick={onCancel}>Cancel</Button>
      <Button type="button" disabled={isSaving} onClick={handleSubmit}>Save {isSaving && <Spin className="ms-2 text-white" indicator={<LoadingOutlined style={{ fontSize: '18px', fill: '#fff' }} spin />} />}</Button>
    </div>
  </div>
  )
};

const EditTooltip = ({ record, id, onUpdate, sendMessage }) => {
  const [open, setOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false)

  const { errors, getFieldProps, handleSubmit, touched, setFieldValue } = useFormik({
    initialValues,
    onSubmit: (values) => {
      let data = { ...values }
      setIsSaving(true)
      updateHSContact(id, data).then((res) => {
        if(res.data?.original?.code == '403'){
          let message = res.data?.original?.error || ""
          sendMessage({ type: 'error', content: <ErrorMessage heading={'Permission denied'} message={message} /> }) 
        } else {
        setOpen(false)
        onUpdate(true)
        }
      }).catch((err) => { })
        .finally(() => { setIsSaving(false) })
    },
  });

  useEffect(() => {
    if (record && open) {
      Object.keys(initialValues).forEach((field) => {
        setFieldValue(field, record?.properties?.[field] ? record?.properties?.[field] : "")
      })
    }
  }, [record, setFieldValue, open])

  const onUpdateHandler = (load = false) => {
    setOpen(false)
    onUpdate(load)
  }

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  return (<Space wrap>
    <Popover
      placement='rightTop'
      content={<EditForm
        handleSubmit={handleSubmit}
        getFieldProps={getFieldProps}
        touched={touched}
        errors={errors}
        isSaving={isSaving}
        onCancel={onUpdateHandler}
      />}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
    >
      <MdEdit className='edit-info-icon' />
    </Popover>
  </Space>
  )
}
export default EditTooltip;