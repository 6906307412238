export const jobStatuses = [
    { id: "2", name: "In Progress" },
    { id: "9", name: "Needs Invoicing" },
    { id: "5", name: "Agency Fees Invoiced NOT PAID" },
    { id: "6", name: "Total Amount Invoiced NOT PAID" },
    { id: "7", name: "PAID and Ready to Run" },
    { id: "8", name: "Agency Fees Paid" },
    { id: "3", name: "Overdue" },
    { id: "4", name: "Completed" },
]

export const billingTypes = [
    { id: "1", name: "Task Billing" },
    { id: "2", name: "Completed Job Billing" },
    { id: "3", name: "Lump Sum / Non Hourly Billing" },
    { id: "4", name: "House Billing / No Charge" },
]

export const billingTypesTwo = [
    { id: "1", name: "Task Billing" },
    { id: "2", name: "Completed Job Billing" },
    { id: "3", name: "Lump Sum / Non Hourly Billing" },
    { id: "4", name: "House Billing / No Charge" },
    { id: "5", name: "Pre-Paid Hours" },
    { id: "6", name: "Bill as you go" },
]

export const billStatus = [
    { id: "0", name: "Unpaid" },
    { id: "2", name: "Paid" },
    { id: "1", name: "Partially Paid" }
]

export const cardTypes = [
    { id: "1", name: "American Express" },
    { id: "2", name: "Bank of America" },
    { id: "3", name: "Capital One" },
    { id: "4", name: "Chase" },
    { id: "5", name: "Citibank" },
    { id: "6", name: "Discover" },
    { id: "7", name: "MasterCard" },
    { id: "8", name: "PNC" },
    { id: "9", name: "Visa" },
    { id: "0", name: "Other" },
]

export const taskStatus = [
    { value: '1', name: 'To do', label: 'To do', id: "1" },
    { value: '2', name: 'In Progress', label: 'In Progress', id: "2" },
    { value: '4', name: 'Review', label: 'Review', id: "4" },
    { value: '3', name: 'Done', label: 'Done', id: "3" },
];

export const notificationStatus = [
    { name: 'Unadressed', id: "1" },
    { name: 'High Importance', id: "2" },
    { name: 'Addressed', id: "3" },
];

export const reqStatuses = [
    { id: "11", name: "Request Sent" },
    { id: "12", name: "Pending Clarification" },
    { id: "16", name: "Job Not Moving Forward" },
    { id: "13", name: "Jobs Not Started" },
    { id: "14", name: "Jobs Started" },
    { id: "15", name: "Jobs Completed" }
]

export const customerPermissions = ["Tasks", "Client Job Requests"]

export const adminPermissions = ["Dashboard", "Customers", "Jobs", "Tasks", "CollabHub", "Notifications", "Billings", "Settings", "Users", "Reports", "Passwords", "Customer Credit Cards", "Customer Service Rates", "Client Job Requests", "HubSpot"]

export const recurrenceFrequency = [
    { id: "30", name: "Monthly" },
    { id: "60", name: "Bi-Monthly" },
    { id: "90", name: "Quarterly" },
    { id: "183", name: "Semi-Annually" },
    { id: "365", name: "Annually" },
]

export const userStatus = [
    { id: "1", name: "Active" },
    { id: "2", name: "Inactive" },
]

export const customerReviewOptions = [
    { id: "1", name: "In Customer Review" },
    { id: "0", name: "Not In Customer Review" }
]

export const billingFrequency = [
    { id: "1", name: "Per Week" },
    { id: "2", name: "Per Month" },
    { id: "3", name: "Unlimited" },
]

export const clientJobRequestStatus = [
    { id: "11", name: "Request Sent" },
    { id: "12", name: "Pending Clarification" },
    { id: "16", name: "Job Not Moving Forward" },
]

export const clientJobStatuses = [
    { id: "11", name: "Request Sent" },
    { id: "12", name: "Pending Clarification" },
    { id: "13", name: "Jobs Not Started" },
    { id: "14", name: "Jobs Started" },
    { id: "15", name: "Jobs Completed" },
    { id: "16", name: "Job Not Moving Forward" },
]

export const billedStatus = [
    { id: "1", name: "Yes" },
    { id: "0", name: "No" },
]

export const paidStatus = [
    { id: "1", name: "Yes" },
    { id: "0", name: "No" },
]

export const jobDocExtentions = []
export const jobActivityDocExtentions = []
export const taskActivityDocExtentions = []
export const custDocExtentions = ["pdf", "docx", "doc", "xls", "xlsx", "csv", "png", "jpg", "gif", "jpeg", "webp"]
export const logoImageExt = ['jpg', 'jpeg', 'png', 'gif', 'webp']

export const jobColumns = {
    clientname: "customer_name",
    jobname: "name",
    type_details: "type_name",
    "Primary Assigned User": "user_first_name",
    total_time: "total_time",
    status: "status",
    desiredDueDate: "due_date",
}

export const taskColumns = {
    clientName: "customer_name",
    job: "project_name",
    taskName: "name",
    desiredDueDate: "desired_due_date",
    assignto: "assigned_user_details",
    completion_date: "completion_date",
    status: "status",
    customerreview: "customer_review"
}

export const notificationColumns = {
    clientname: "customer_name",
    jobname: "job_name",
    taskname: "task_name",
    sendername: "sender_first_name",
    content: "content",
    jobstatus: "job_status",
    taskstatus: "task_status",
    datesent: "created_at"
}

export const jobReqColumns = {
    clientname: "customer_name",
    jobname: "name",
    requestStatus: "request_status",
    lastComment: "last_comment",
    requestedBy: "user_first_name",
    convertedDate: "converted_date"
}

export const billColumns = {
    clientName: "customer_name",
    jobName: "project_name",
    taskName: "task_name",
    billingType: "billing_type",
    responsibleUser: "assigned_user_details",
    dateOfCompletion: "item_completion_date",
    cost: "cost",
    billed: "is_billed",
    paid: "is_paid",
}

export const hsContactColumns = {
    firstname: "firstname",
    contactname: "contactname",
    email: "email",
    phone: "phone",
    hubspot_owner_id: "hubspot_owner_id",
    primary_company_name: "primary_company_name",
    lastmodifieddate: "lastmodifieddate",
    createdate: "createdate",
    hs_lead_status: "hs_lead_status",
}

export const hsCompanyColumns = {
    name: "name",
    createdate: "createdate",
    phone: "phone",
    city: "city",
    country: "country",
    industry: "industry",
    lastmodifieddate: "lastmodifieddate",
    hubspot_owner_id: "hubspot_owner_id",
}

export const dateFilterOpts = [
    { id: "today", name: "Today" },
    { id: "yesterday", name: "Yesterday" },
    { id: "tomorrow", name: "Tomorrow" },
    { id: "this_week", name: "This week" },
    { id: "this_week_so_far", name: "This week so far" },
    { id: "last_week", name: "Last week" },
    { id: "next_week", name: "Next week" },
    { id: "this_month", name: "This month" },
    { id: "this_month_so_far", name: "This month so far" },
    { id: "last_month", name: "Last month" },
    { id: "next_month", name: "Next month" },
    { id: "this_quarter", name: "This quarter" },
    { id: "this_fiscal_quarter", name: "This fiscal quarter" },
    { id: "this_quarter_so_far", name: "This quarter so far" },
    { id: "this_fiscal_quarter_so_far", name: "This fiscal quarter so far" },
    { id: "last_quarter", name: "Last quarter" },
    { id: "last_fiscal_quarter", name: "Last fiscal quarter" },
    { id: "next_quarter", name: "Next quarter" },
    { id: "next_fiscal_quarter", name: "Next fiscal quarter" },
    { id: "this_year", name: "This year" },
    { id: "this_fiscal_year", name: "This fiscal year" },
    { id: "this_year_so_far", name: "This year so far" },
    { id: "this_fiscal_year_so_far", name: "This fiscal year so far" },
    { id: "last_year", name: "Last year" },
    { id: "last_fiscal_year", name: "Last fiscal year" },
    { id: "next_year", name: "Next year" },
    { id: "next_fiscal_year", name: "Next fiscal year" },
    { id: "last_7_days", name: "Last 7 days" },
    { id: "last_14_days", name: "Last 14 days" },
    { id: "last_30_days", name: "Last 30 days" },
    { id: "last_60_days", name: "Last 60 days" },
    { id: "last_90_days", name: "Last 90 days" },
    { id: "last_180_days", name: "Last 180 days" },
    { id: "last_365_days", name: "Last 365 days" }
];

export const hsAssociations = {
    contact: { associationCategory: 'HUBSPOT_DEFINED', associationTypeId: 202, associationType: 'note_to_contact', associationSection: 'contacts' },
    company: { associationCategory: 'HUBSPOT_DEFINED', associationTypeId: 190, associationType: 'note_to_company', associationSection: 'companies' },
    deal: { associationCategory: 'HUBSPOT_DEFINED', associationTypeId: 190, associationType: 'note_to_deal', associationSection: 'deals' },
}

export const hsObjectSource = [
    { id: "CRM_UI", value: "CRM_UI", name: "CRM UI" },
    { id: "CRM_UI_IMPORT", value: "CRM_UI_IMPORT", name: "CRM UI Import" },
    { id: "API", value: "API", name: "API" },
    { id: "WORKFLOW", value: "WORKFLOW", name: "Workflow" },
    { id: "INTEGRATION", value: "INTEGRATION", name: "Integration" },
    { id: "FORMS", value: "FORMS", name: "Forms" },
    { id: "CHAT", value: "CHAT", name: "Chat" },
    { id: "EMAIL", value: "EMAIL", name: "Email" },
    { id: "MEETINGS", value: "MEETINGS", name: "Meetings" }
]

export const contactGroupLabels = {
    contact_activity: "Contact activity",
    deal_information: "Deal information",
    sales_properties: "Sales properties",
    contactinformation: "Contact information",
    analyticsinformation: "Web analytics history",
    conversioninformation: "Conversion information",
    emailinformation: "Email information",
    facebook_ads_properties: "Facebook ads properties",
};

export const hsDealsColumns = {
    dealname: "dealname",
    dealstage: "dealstage",
    closedate: "closedate",
    hubspot_owner_id: "hubspot_owner_id",
    amount: "amount",
}

export const timelineOptions = [
    { id: "PAST_30_DAYS", value: "PAST_30_DAYS", name: "Last 30 days" },
    { id: "PAST_60_DAYS", value: "PAST_60_DAYS", name: "Last 60 days" },
    { id: "PAST_90_DAYS", value: "PAST_90_DAYS", name: "Last 90 days" },
    { id: "ALL_TIME", value: "ALL_TIME", name: "All time" },
    { id: "CUSTOM_DATE_RANGE", value: "CUSTOM_DATE_RANGE", name: "Custom date" },
]