/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { formatCurrency } from '../../../../../Utils/helpers';
import { parseDateTimeString } from '../../../../../Utils/dateFormat';
import AppointmentDropdown from '../../AppointmentDropdown';
import { sortObjectsByAttribute } from '../../../../../Utils/sortFunctions';
import { updateHSDeal } from '../../../../../API/authCurd';
import { Button, Popover } from 'antd';
import { ErrorMessage } from '../../../../hooks/hubspot';

const DealCard = ({ dealData, dealsPipelines, sendMessage, fetchData, handleRemove, setShowLoader, associate_to, fetchId, onDealPreviewClick }) => {
    const navigate = useNavigate()
    const [stageOptions, setStageOptions] = useState([])
    const [dealStage, setDealStage] = useState(null)

    const getName = (data) => {
        let name = `${data?.properties?.dealname ? data?.properties?.dealname : ""}`.trim()
        return name ? name : '--'
    }

    const onDealStageChange = (value) => {
        setDealStage(value)
        let data = { dealstage: value }
        setShowLoader((prev) => ({ ...prev, [associate_to]: true }))
        updateHSDeal(dealData?.id, data).then((res) => {
            if(res.data?.original?.code == '403'){
                let message = res.data?.original?.error || ""
                sendMessage({ type: 'error', content: <ErrorMessage heading={'Permission denied'} message={message} /> }) 
              } else {
            sendMessage({ content: 'Success. Your changes were updated.' })
            fetchData(fetchId)
              }
        })
    }

    useEffect(() => {
        if (dealData) {
            setDealStage(dealData?.properties?.dealstage)
            let stages = dealsPipelines?.find((item) => item.id == dealData?.properties?.pipeline)
            setStageOptions(stages?.stages ? stages?.stages : [])
        }
    }, [dealData, dealsPipelines])

    const navigateTo = (e, dealId) => {
        if (dealId) {
            navigate(`/hubspot/deals/${dealId}`)
        }
    }

    const popoverContent = (
        <div className='d-flex flex-column'>
            <Button className='me-2' danger onClick={(e) => { handleRemove(e, dealData) }}>Remove association</Button>
        </div>
    );

    return (
        <div className='association-card'>
            <span className='more-button' style={{right: '50px'}} onClick={() => { onDealPreviewClick(dealData) }}>Preview</span>
            <Popover
                placement='bottom'
                content={popoverContent}
                trigger="hover"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
                <span className='more-button'>More</span>
            </Popover>
            <p className='association-name' onClick={(e) => { navigateTo(e, dealData?.id) }}>{getName(dealData)}</p>
            <p className='association-email'>Amount: {`${dealData?.properties?.amount ? formatCurrency(dealData?.properties?.amount, false) : '--'}`}</p>
            <p className='association-phone'>Close date: {`${dealData?.properties?.closedate ? parseDateTimeString(dealData?.properties?.closedate, 2) : '--'}`}</p>
            <div className='appointment-date deal-preview'>
                <label style={{ display: "block" }}>Stage: </label>
                <AppointmentDropdown
                    options={[...sortObjectsByAttribute(stageOptions, 'displayOrder').map(({ id, label }) => ({ value: id, label: label }))]}
                    onSelect={onDealStageChange}
                    fieldValue={dealStage}
                    placeholder='Select a stage'
                />
            </div>
        </div>
    )
}

export default DealCard