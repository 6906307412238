/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import MyTooltip from './MyTooltip'
import TableBtn from './TableBtn'
import { BiSolidEdit } from 'react-icons/bi'
import { FaRegCopy } from 'react-icons/fa6'
import { RiDeleteBin6Fill, RiFileSearchFill } from 'react-icons/ri'
import { FiChevronLeft } from 'react-icons/fi'
import { Table } from 'antd'
import { taskStatus } from '../../../Utils/staticdata'
import UpdateForm from '../../popups/jobspopups/updateForm'
import { JobContext } from '../../contexts/JobContext'
import TaskUpdate from '../../popups/taskpops/TaskUpdate'
import { TaskContext } from '../../contexts/TaskContext'
import { deleteJob, deleteTask, getSingleJob, getSingleTask, postDuplicateTaskData } from '../../../API/authCurd'
import { confirmWindow } from './Dialogue'
import { ActivityNotesContext } from '../../contexts/ActivityNotesContext'
import ActivityPopups from '../../popups/taskpops/ActivityPopups'
import { CallTimeEntryContext } from '../../contexts/CallTimeEntryContext'
import CallEntryPopup from '../../popups/taskpops/CallEntryPopup'
import { FaClipboardList } from 'react-icons/fa';
import { AddJobTaskContext } from '../../contexts/AddJobTaskContext'
import JobTask from '../../pages/Jobs/JobTask'
import CallHistory from '../../pages/Jobs/CallHistory'
import AssignUser from '../../pages/Task/AssignUser'
import { MdTask } from "react-icons/md";
import { useLocation, useNavigate } from 'react-router-dom'
import Audittrail from '../../pages/Jobs/AuditTrail'
import { IoMdCall } from "react-icons/io";
import UpdateClientForm from '../../pages/ClientJobRequest/updateClientForm'
import { ClientJobContext } from '../../contexts/ClientJobContext'
import { useConfirm } from '../../hooks/use-confirm'

const deleteTaskPages = ["/notifications"]

const ActivityRightHeader = ({ isJob, selectedTask, selectedJob, fetchList, onClose, fromJob, setSelectedJob, setSelectedTask, openJobNotes, showIcons }) => {
    const {
        jobSectionCodes, JobPaymentTerms, JobCustomerList, selectedJobForSection, setSelectedJobForSection,
        JobResponsibleUser, JobFormError, setJobFormError, jobSuccessMessage, setJobSuccessMessage,
        getJobCustomersList, getJobResponsibleUsers, getJobSectionCodes
    } = useContext(JobContext)
    const {
        TaskServiceTypes, TaskPaymentTerms, taskCustomerList, taskSectionUpdateDetail, settaskSectionUpdateDetail,
        taskFormError, setTaskFormError, taskUpdatedtaskData, setTaskUpdatetaskdData, taskSuccessMessage,
        setTaskSuccessMessage, getServiceTypes, getCustomersList
    } = useContext(TaskContext)
    const [tasks, setTasks] = useState([])
    const [showDelete, setShowDelete] = useState(false)
    const {
        taskActivePopup, taskActivityNotes, setTaskActivityNotes, selectedTaskForActivity, taskFileList, isJob: isJobContext,
        getActivityNotesByTaskId, taskDocumentUploadCustomRequest, handleTaskDocDownload, taskDocOnChangehandler,
        onTaskActivityPopupCloseHandler, handleTaskDocRemove, onNoteButtonClick, taskDocFileList, removeTaskDocFromJob,
        setSelectedJobForActivity, setSelectedTaskForActivity
    } = useContext(ActivityNotesContext)
    const {
        openCallEntryPopup, callEntryServiceTypes, callEntryPaymentTerms, callEntryCustomerList, callEntryUpdateDetail,
        setCallEntryUpdateDetail, callEntryFormError, setCallEntryFormError, updatedCallEntryData, setUpdateCallEntryData,
        onCallEntryCancelBtnHandler, updateCallEntryPopupOpen, isCallEntryEdit, callEntryIbUsers, callSuccessMessage,
        setCallSuccessMessage
    } = useContext(CallTimeEntryContext)
    const {
        addJobTaskFormError, setAddJobTaskFormError, addJobTaskFormValue, setAddJobTaskFormValue, AddJobTaskServiceTypes,
        addJobTaskCustList, addJobTaskCancelBtnHandler, jobsForAddTask, setJobsForAddTask, addJobTaskPopup, onJobTaskAddClickHandler
    } = useContext(AddJobTaskContext)
    const {
        clientJobCustomerList, selectedClientJob, setSelectedClientJob, clientJobError, setClientJobError, clientJobPopup,
        onClientJobCancelHandler, updateClientJobPopup
    } = useContext(ClientJobContext)
    const [isOpenFromJob, setIsOpenFromJob] = useState(false)
    const [callHistoryPopup, setCallHistoryPopup] = useState(false);
    const [selectedJobForCallLog, setSelectedJobCallLog] = useState(null);
    const [selectedAssignTask, setSelectedAssignTask] = useState(null)
    const [openJobUpdatePopup, setOpenJobUpdatePopup] = useState(false)
    const [openTaskUpdatePopup, setOpenTaskUpdatePopup] = useState(false)
    let navigate = useNavigate()
    const [auditTrailpopUps, setAuditTrailPopUps] = useState(false);
    const [selectedJobForAuditTrail, setSelectedJobAuditTrail] = useState(null);
    const { DeleteConfirmModal, confirmDeleteWindow } = useConfirm();
    const location = useLocation()
    const [jobRequestedBy, setJobRequestedBy] = useState(null);
    let userType = localStorage.getItem('usertype')

    useEffect(() => {
        if (selectedJob?.tasks_details) {
            setTasks(selectedJob?.tasks_details)
        } else {
            setTasks([])
        }
    }, [selectedJob])

    const navigateToTask = (e, record) => {
        e.preventDefault()
        if (isJob) {
            setIsOpenFromJob(true)
            let data = { task_id: record.id }
            onNoteButtonClick(e, data)
        } else {
            setIsOpenFromJob(false)
        }
    }

    let columnsDef = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: 300,
            render: (text, record) => {
                return record?.name ? <div><a href='' onClick={(e) => { navigateToTask(e, record) }} className='text-decoration-none'>{record?.name}</a></div> : null
            }
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            width: 100,
            render: (text, record) => {
                let status = taskStatus?.find((s) => record.status == s.id)
                return <div>{status?.name ? status?.name : ""}</div>
            }
        },
    ]

    const backToJob = (e, record) => {
        e.preventDefault()
        onClose()
        setIsOpenFromJob(false)
        if (openJobNotes && !fromJob) {
            openJobNotes(e, record)
        }
    }

    const editJobOrTask = (e) => {
        e.preventDefault()
        if (isJob) {
            if (selectedJob?.request_status == 11 || selectedJob?.request_status == 12 || selectedJob?.request_status == 16) {
                updateClientJobPopup({ id: selectedJob.id })
            } else {
                updateJobPopupOpen(selectedJob.id)
            }
        } else {
            if (selectedTask?.id) {
                if (selectedTask?.name == "Call Time Entry") {
                    updateCallEntryPopupOpen({ name: selectedTask?.name, id: selectedTask?.id })
                } else {
                    updateTaskPopupOpen(selectedTask?.name, selectedTask?.id)
                }
            }
        }
    }

    const deleteJobOrTask = async (e) => {
        e.preventDefault()
        if (isJob) {
            let msg = selectedJob?.billable_items?.is_billed == 1 ? "The job you are attempting to delete has already been billed. Deleting this job will affect generated bills.\n\nAre you sure you want to delete this job?" : "Are you sure you want to delete this job?"
            let isConfirm = await confirmDeleteWindow(msg)
            if (isConfirm && selectedJob?.id) {
                deleteJob(selectedJob?.id).then((res) => {
                    fetchList()
                    onClose(selectedJob?.id)
                    setIsOpenFromJob(false)
                }).catch(() => { })
            }
        } else {
            let msg = (selectedTask?.billable_items?.is_billed == 1 || selectedTask?.billable_items_job?.is_billed == 1) ? "The task you are attempting to delete has already been billed. Deleting this task will affect generated bills.\n\nAre you sure you want to delete this task?" : "Are you sure you want to delete this task?"
            const isConfirmed = await confirmDeleteWindow(msg)
            if (isConfirmed && selectedTask?.id) {
                deleteTask(selectedTask?.id).then((res) => {
                    if (deleteTaskPages.includes(location.pathname)) {
                        fetchList()
                    }
                    onClose()
                    setIsOpenFromJob(false)
                }).catch(() => { })
            }
        }
    }

    useEffect(() => {
        if (isJob) {
            const hasStatusDone = selectedJob?.tasks_details?.some(item => item.status == 3);
            if (hasStatusDone) {
                setShowDelete(false)
            } else {
                if (location.pathname == '/clientjobrequest') {
                    if ((userType == 3 && (selectedJob?.request_status == 11 || selectedJob?.request_status == 12 || selectedJob?.request_status == 16)) ||
                        ((userType == 1 || userType == 2))) {
                        setShowDelete(true)
                    } else {
                        setShowDelete(false)
                    }
                } else {
                    setShowDelete(true)
                }
            }
        } else {
            if (selectedTask?.status == 3 || userType == "3") { setShowDelete(false) }
            else { setShowDelete(true) }
        }
    }, [selectedJob, selectedTask, isJob])

    const addJobTaskIconClick = (e, record) => {
        e.preventDefault()
        if (record && record?.type == 6) {
            openCallHistoryPopup(e, record)
        } else {
            let data = { customer_id: record.customer, id: record.id }
            onJobTaskAddClickHandler(data)
        }
    }

    function openTaskUpdateModel(taskId, msg) {
        if (taskId) {
            setTaskSuccessMessage(msg ? msg : "")
            setTimeout(() => {
                setTaskSuccessMessage("")
            }, 10000)
            updateTaskPopupOpen("name", taskId)
        }
    }

    // Job Call history close button handler
    const closeCallHistoryPopup = () => {
        setSelectedJobCallLog(null)
        setCallHistoryPopup(false)
    }

    const openCallHistoryPopup = (e, record) => {
        e.preventDefault()
        setSelectedJobCallLog(record)
        setCallHistoryPopup(true)
    }

    // Get single job record 
    function getSingleJobData(id) {
        return getSingleJob(id).then((res) => {
            return res?.data
        }).catch(err => {
            return
        })
    }

    // Get single task data by ID
    function getSingleTaskData(id) {
        return getSingleTask(id).then((res) => {
            return res.data
        }).catch(() => {
            return
        })
    }

    // Update Job cancel button handler
    const updateJobCancelClickHandler = async (record) => {
        setOpenJobUpdatePopup(false)
        setJobFormError({})
        setJobSuccessMessage("")
        if (record) {
            await getSingleJobData(record?.id).then((res) => {
                if (res?.job) {
                    setSelectedJob(res.job)
                }
            }).catch(() => {
                setSelectedJob([])
            })
        }
    }

    // Open Job edit popup
    const updateJobPopupOpen = (id) => {
        setJobFormError({})
        setOpenJobUpdatePopup(true)
        getSingleJobData(id).then((res) => {
            if (res?.job) {
                setSelectedJobForSection(res.job)
            }
        }).catch(() => {
            setSelectedJobForSection([])
        })
        getJobCustomersList()
        getJobResponsibleUsers()
        getJobSectionCodes()
    }

    // Open Task edit popup
    const updateTaskPopupOpen = (name, id) => {
        setTaskFormError({})
        getSingleTaskData(id).then((res) => {
            if (res?.Task) {
                settaskSectionUpdateDetail(res.Task)
            }
        }).catch(() => {
            settaskSectionUpdateDetail([])
        })
        setOpenTaskUpdatePopup(true);
        getServiceTypes();
        getCustomersList();
    }

    // Update Task cancel button handle
    const updateTaskCancelBtnHandler = async (record) => {
        setOpenTaskUpdatePopup(false);
        setTaskSuccessMessage("")
        if (record) {
            if (isJob) {
                getSingleJobData(record?.project).then((res) => {
                    if (res?.job) {
                        setSelectedJob(res.job)
                    }
                }).catch(() => {
                    setSelectedJob([])
                })
            } else {
                await getSingleTaskData(record?.id).then((res) => {
                    if (res?.Task) {
                        setSelectedTask(res.Task)
                    }
                }).catch(() => {
                    setSelectedTask([])
                })
            }
        }
    }

    // Show Task button click handler
    const showTasksButtonOnclickHandler = (e, record) => {
        e.preventDefault()
        if (record) {
            let targetUrl = `/tasks?job_id=${record.id}&id=${record.customer}&is_call_entry=${record?.type == 6 ? 1 : 0}`
            if (location.pathname == '/tasks') {
                window.location.href = `${process.env.REACT_APP_BACKEND_URL}${targetUrl}` 
                // window.location.href = `${targetUrl}`
            } else {
                navigate(targetUrl)
            }
        }
    }

    // Job audit trail open button handler
    const openJobAuditTrailPopup = (e, record) => {
        e.preventDefault()
        setSelectedJobAuditTrail(record)
        setAuditTrailPopUps(true)
    }

    // Job audit trail close button handler
    const closeJobAuditTrailPopup = () => {
        setAuditTrailPopUps(false)
        setSelectedJobAuditTrail(null)
    }

    const duplicateTask = (e, record) => {
        e.preventDefault()
        let isConfirm = confirmWindow("Are you sure you want to duplicate this task?")
        if (isConfirm) {
            const payload = { task_id: record.id, };
            postDuplicateTaskData(payload).then(() => {
                if (fromJob) {
                    getSingleJobData(record?.project).then((res) => {
                        if (res?.job) {
                            setSelectedJob(res.job)
                        }
                    }).catch(() => {
                        setSelectedJob([])
                    })
                } else {
                    fetchList()
                }
            }).catch((err) => {
                console.log("Err: ", err)
            })
        }
    };

    const taskActivityCloseBtnHandler = (record) => {
        if (record) {
            getSingleJobData(record?.project).then((res) => {
                if (res?.job) {
                    setSelectedJob(res.job)
                }
            }).catch(() => {
                setSelectedJob([])
            })
        }
        onTaskActivityPopupCloseHandler()
    }

    const openJobUpdateAfterConvert = (jobId) => {
        updateJobPopupOpen(jobId)
    }

    const openActivityLogs = (record) => {
        setJobRequestedBy(record?.customer)
      }
    

    const onRowTableClick = (e, record) => {
        const target = e.target
        if (!target.className.includes('update-task-btn')) {
          openActivityLogs(record)
        }
      }

    return (
        <>
            <div className='task-wrapper'>
                <div className='d-flex align-items-center justify-content-between mb-4 icon-wrapper'>
                    <div className='d-flex'>
                        <MyTooltip title={isJob ? "Edit Job" : "Edit Task"}>
                            <TableBtn className="activeLog update-task-btn me-2" onclick={(e) => { editJobOrTask(e) }}>
                                <BiSolidEdit />
                            </TableBtn>
                        </MyTooltip>
                        {(isJob && userType != "3" && !showIcons) && <MyTooltip title="Add Task">
                            <TableBtn className="activeLog update-task-btn me-2" onclick={(e) => { addJobTaskIconClick(e, selectedJob) }}>
                                <FaClipboardList />
                            </TableBtn>
                        </MyTooltip>}
                        {(isJob && userType != "3" && !showIcons) && <MyTooltip title="Show Tasks">
                            <TableBtn className="activeLog update-task-btn me-2" onclick={(e) => showTasksButtonOnclickHandler(e, selectedJob)}>
                                <MdTask />
                            </TableBtn>
                        </MyTooltip>}
                        {(isJob && userType != "3" && !showIcons) && <MyTooltip title="Audit Trail">
                            <TableBtn className="activeLog update-task-btn me-2" onclick={(e) => openJobAuditTrailPopup(e, selectedJob)}>
                                <RiFileSearchFill />
                            </TableBtn>
                        </MyTooltip>}
                        {((isJob && userType != "3") && selectedJob?.type == 6) && <MyTooltip title="Call Logs">
                            <TableBtn className="activeLog update-task-btn me-2" onclick={(e) => openCallHistoryPopup(e, selectedJob)}>
                                <IoMdCall />
                            </TableBtn>
                        </MyTooltip>}
                        {(!isJob && userType != "3") && <TableBtn className="activeLog update-task-btn me-2" >
                            <AssignUser
                                record={selectedTask}
                                paginationData={{}}
                                getTaskListPagination={() => { }}
                                selectedTasks={selectedAssignTask}
                                setSelectedTasks={setSelectedAssignTask}
                                filters={{}}
                            />
                        </TableBtn>}
                        {(!isJob && selectedTask?.name != "Call Time Entry" && userType != "3") && <MyTooltip title="Duplicate Task">
                            <TableBtn className="activeLog update-task-btn me-2" onclick={(e) => { duplicateTask(e, selectedTask) }}>
                                <FaRegCopy />
                            </TableBtn>
                        </MyTooltip>}
                        {showDelete && <MyTooltip title={isJob ? "Delete Job" : "Delete Task"}>
                            <TableBtn className="activeLog update-task-btn me-2" onclick={(e) => { deleteJobOrTask(e) }}>
                                <RiDeleteBin6Fill />
                            </TableBtn>
                        </MyTooltip>}
                    </div>
                    {(!isJob && userType != "3") && <div>
                        <a href='' onClick={(e) => backToJob(e, selectedTask)}>
                            <FiChevronLeft /> Back to Job
                        </a>
                    </div>}
                </div>
                {isJob && <div className='mb-3 tasks inner-task-table'>
                    <h6 className='content-title'>Tasks</h6>
                    <div className='tasks-rows'>
                        <Table
                            columns={columnsDef}
                            dataSource={tasks ? tasks.filter(record => record.customer_review == 1 || userType != "3" ) : []}
                            sticky={{
                                offsetHeader: 0,
                            }}
                            onRow={(record, rowIndex) => ({
                                onClick: (e) => { onRowTableClick(e, record) }
                              })}
                            showHeader={false}
                            pagination={false}
                        />
                    </div>
                </div>}
            </div>
            {openJobUpdatePopup && <div className={`mainpopups`}>
                <UpdateForm
                    jobCodes={jobSectionCodes}
                    paymentTerms={JobPaymentTerms}
                    customerList={JobCustomerList}
                    getJobList={fetchList}
                    selectedJob={selectedJobForSection}
                    onClick={() => {
                        updateJobCancelClickHandler(selectedJobForSection)
                    }}
                    responsibleUser={JobResponsibleUser}
                    formError={JobFormError}
                    setFormError={setJobFormError}
                    getJobListPagination={fetchList}
                    paginationData={{}}
                    setSelectedJob={setSelectedJobForSection}
                    filters={{}}
                    setFilters={null}
                    successMessage={jobSuccessMessage}
                    setSuccessMessage={setJobSuccessMessage}
                    isClientRequest={location.pathname == '/clientjobrequest'}
                />
                <div className="blurBg"></div>
            </div>}
            {openTaskUpdatePopup && <div className={`mainpopups`}>
                <TaskUpdate
                    serviceTypes={TaskServiceTypes}
                    paymentTerms={TaskPaymentTerms}
                    customerList={taskCustomerList}
                    getTaskList={fetchList}
                    taskUpdateDetail={taskSectionUpdateDetail}
                    onClick={() => {
                        updateTaskCancelBtnHandler(taskSectionUpdateDetail)
                    }}
                    formError={taskFormError}
                    setFormError={setTaskFormError}
                    paginationData={{}}
                    getTaskListPagination={fetchList}
                    updatedtaskData={taskUpdatedtaskData}
                    setUpdatetaskdData={setTaskUpdatetaskdData}
                    settaskUpdateDetail={settaskSectionUpdateDetail}
                    isCallEntry={false}
                    filters={{}}
                    successMessage={taskSuccessMessage}
                    setSuccessMessage={setTaskSuccessMessage}
                />
                <div className="blurBg"></div>
            </div>}
            {openCallEntryPopup && <div className={`mainpopups`}>
                <CallEntryPopup
                    serviceTypes={callEntryServiceTypes}
                    paymentTerms={callEntryPaymentTerms}
                    customerList={callEntryCustomerList}
                    getTaskList={fetchList}
                    taskUpdateDetail={callEntryUpdateDetail}
                    onClick={() => {
                        onCallEntryCancelBtnHandler()
                    }}
                    formError={callEntryFormError}
                    setFormError={setCallEntryFormError}
                    paginationData={{}}
                    getTaskListPagination={fetchList}
                    updatedtaskData={updatedCallEntryData}
                    setUpdatetaskdData={setUpdateCallEntryData}
                    settaskUpdateDetail={setCallEntryUpdateDetail}
                    isCallEntry={isCallEntryEdit}
                    filters={{}}
                    ibUsers={callEntryIbUsers}
                    successMessage={callSuccessMessage}
                    setSuccessMessage={setCallSuccessMessage}
                />
                <div className="blurBg"></div>
            </div>}
            {taskActivePopup && <div className={`mainpopups`}>
                <ActivityPopups
                    onClick={() => {
                        setIsOpenFromJob(false)
                        setJobRequestedBy(null)
                        taskActivityCloseBtnHandler(selectedTaskForActivity)
                    }}
                    activityNotes={taskActivityNotes}
                    setActivityNotes={setTaskActivityNotes}
                    selectedTask={selectedTaskForActivity}
                    getActivityNotesByTaskId={getActivityNotesByTaskId}
                    documentUploadCustomRequest={taskDocumentUploadCustomRequest}
                    fileList={taskFileList}
                    docOnChangehandler={taskDocOnChangehandler}
                    handleDownload={handleTaskDocDownload}
                    handleRemove={handleTaskDocRemove}
                    isJob={isJobContext}
                    taskFileList={taskDocFileList}
                    removeTaskDocFromJob={removeTaskDocFromJob}
                    fetchList={() => { fetchList() }}
                    fromJob={isOpenFromJob}
                    setSelectedJob={setSelectedJobForActivity}
                    setSelectedTask={setSelectedTaskForActivity}
                    isCustomerReview={true}
                    jobRequestedBy={jobRequestedBy}
                />
                <div className="blurBg"></div>
            </div>}
            {addJobTaskPopup && <div className={`mainpopups`}>
                <JobTask
                    onClick={addJobTaskCancelBtnHandler}
                    serviceTypes={AddJobTaskServiceTypes}
                    customerList={addJobTaskCustList}
                    formError={addJobTaskFormError}
                    setFormError={setAddJobTaskFormError}
                    formValue={addJobTaskFormValue}
                    setFormValue={setAddJobTaskFormValue}
                    jobs={jobsForAddTask}
                    setJobs={setJobsForAddTask}
                    isFromJob={true}
                    openModel={openTaskUpdateModel}
                />
                <div className="blurBg"></div>
            </div>}
            {callHistoryPopup && <div className={`mainpopups`}>
                <CallHistory
                    selectedJobForCallLog={selectedJobForCallLog}
                    onClick={closeCallHistoryPopup}
                />
                <div className="blurBg"></div>
            </div>}
            {auditTrailpopUps && <div className={`mainpopups`}>
                <Audittrail
                    onClick={closeJobAuditTrailPopup}
                    selectedJobForAuditTrail={selectedJobForAuditTrail}
                />
                <div className="blurBg"></div>
            </div>}
            {clientJobPopup && <div className={`mainpopups`}>
                <UpdateClientForm
                    customerList={clientJobCustomerList}
                    selectedJob={selectedClientJob}
                    setSelectedJob={setSelectedClientJob}
                    onClick={onClientJobCancelHandler}
                    formError={clientJobError}
                    setFormError={setClientJobError}
                    getJobListPagination={() => { fetchList() }}
                    paginationData={{}}
                    isClientRequest={location.pathname == '/clientjobrequest'}
                    filters={{}}
                    openJobUpdateAfterConvert={openJobUpdateAfterConvert}
                />
                <div className="blurBg"></div>
            </div>}
            {<DeleteConfirmModal />}
        </>
    )
}

export default ActivityRightHeader