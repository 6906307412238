/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import * as FaIcons from "react-icons/fa6";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import "../../SCSS/ContactPage.scss"
import Button from '../../commonModules/UI/Button';
import '../../SCSS/popups.scss';
import AccordionNote from '../../commonModules/UI/AccordianNote';
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoChevronBackSharp } from "react-icons/io5";
import CreateNoteModal from '../../commonModules/UI/CreateNoteModal';
import { useContactOwners } from '../../hooks/contacts';
import { checkHubSpotAccess, deleteHSDeal, deleteHSNotes, getSingleHSDeal, updateHSDeal } from '../../../API/authCurd';
import { DatePicker, message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useFormik } from 'formik';
import { DealCloseDateEditMessage, ErrorMessage, PermissionMessage, useHsNotes, useSetPrimary } from '../../hooks/hubspot';
import { convertDateToTimestamp, parseDateTimeString } from '../../../Utils/dateFormat';
import { hsObjectSource } from '../../../Utils/staticdata';
import { FaRegEdit } from "react-icons/fa";
import EditDealTooltip from '../../commonModules/UI/EditDealTooltip';
import dayjs from "dayjs"
import 'dayjs/locale/en';
import { useDealsPipelines, useDealsProps } from '../../hooks/deals';
import { sortByConcatenatedString, sortObjectsByAttribute } from '../../../Utils/sortFunctions';
import AppointmentDropdown from '../../commonModules/UI/AppointmentDropdown';
import { useDeleteHs } from '../../hooks/useDeleteHs';
import SearchDropdown from '../../commonModules/UI/SearchDropdown';
import { checkPermission, extractIds, formatCurrency } from '../../../Utils/helpers';
import ContactAssociation from '../../commonModules/UI/associationForms/contact/ContactAssociation';
import CompanyAssociation from '../../commonModules/UI/associationForms/company/CompanyAssociation';
import DealAssociation from '../../commonModules/UI/associationForms/deals/DealAssociation';
import { CustomHSDeleteDealsPrimary, CustomHSDeletePrimary } from '../../commonModules/UI/Dialogue';
import { GlobalSearch } from '../../contexts/GlobalSearchContext';
import { ContactOffCanvas } from '../../commonModules/UI/PreviewButton';
import { CompanyOffCanvas } from '../../commonModules/UI/PreviewButtonCompany';
import { DealsOffCanvas } from '../../commonModules/UI/PreviewButtonDeal';

const initialValues = {
  hubspot_owner_id: '',
  dealtype: '',
  hs_priority: '',
}

const HubspotDealsDetails = () => {
  const { id } = useParams();
  const [openCreateNote, setOpenCreateNote] = useState(false);
  const [isSaving, setIsSaving] = useState(false)
  const { confirmDeleteWindow, DeleteHSConfirmModal } = useDeleteHs();
  const { contactOwners, getContactOwners } = useContactOwners();
  const { getDealsProps, dealTypes, dealPriority } = useDealsProps();
  const { notes, setNotes, getNotes, notesPaging, fetchNextNotes } = useHsNotes();
  const [dealsData, setDealsData] = useState(null)
  const navigate = useNavigate()
  const [tagList, setTagList] = useState([])
  const [notesLoading, setNotesLoading] = useState(false);
  const [dealsLoading, setDealsLoading] = useState(false);
  const [closeDate, setCloseDate] = useState(null)
  const [dealStage, setDealStage] = useState(null)
  const { dealsPipelines, getDealsPipelines } = useDealsPipelines();
  const [stageOptions, setStageOptions] = useState([])
  const [messageApi, contextHolder] = message.useMessage();
  const { confirmSetPrimaryWindow, SetPrimaryConfirmModal } = useSetPrimary();
  const [hasHsAccess, setHasHsAccess] = useState(true)
  const [callApi, setCallApi] = useState(false)
  const { userData } = useContext(GlobalSearch)

  const [contactAssociations, setContactAssociations] = useState([])
  const [companyAssociations, setCompanyAssociations] = useState([])
  const [dealAssociations, setDealAssociations] = useState([])
  const [dealsPrimaryCompany, setDealsPrimaryCompany] = useState(null)

  const [showModalOne, setShowModalOne] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({});
  const [confirmCallback, setConfirmCallback] = useState(null);
  const [selectedValue, setSelectedValue] = useState("")
  const [dropdown, setDropdown] = useState([])
  const [showLoader, setShowLoader] = useState({ contacts: false, companies: false, deals: false })

  const [selectedContactPreview, setSelectedContactPreview] = useState(null)
  const [showContactDrawer, setShowContactDrawer] = useState(false);

  const [showCompanyDrawer, setShowCompanyDrawer] = useState(false);
  const [selectedCompanyPreview, setSelectedCompanyPreview] = useState(null);

  const [showDealDrawer, setShowDealDrawer] = useState(false);
  const [selectedDealPreview, setSelectedDealPreview] = useState(null);

  useEffect(() => {
    let permission = checkPermission("HubSpot")
    if (!permission) {
      navigate("/dashboard")
    }
  }, [])

  const { errors, resetForm, getFieldProps, handleSubmit, touched, setFieldValue, values } = useFormik({
    initialValues,
    onSubmit: (values) => {
      let data = { ...values }
      if (data.hubspot_owner_id == 'default') { data.hubspot_owner_id = "" }
      setIsSaving(true)
      updateHSDeal(id, data).then((res) => {
        if(res.data?.original?.code == '403'){
          let message = res.data?.original?.error || ""
          sendAlert({ type: 'error', content: <ErrorMessage heading={'Permission denied'} message={message} /> }) 
        } else {
        sendAlert({ content: 'Changes saved' })
        getSingleDealData(id)
        }
      }).catch((err) => { })
        .finally(() => { setIsSaving(false) })
    },
  });

  function getSingleDealData(id, loader = false) {
    if (loader) {
      setDealsLoading(true)
      setShowLoader({ contacts: true, companies: true, deals: true })
    }
    getSingleHSDeal(id).then((res) => {
      if (res?.data?.original?.Deals) {
        let primaryCompany = res?.data?.original?.associations?.primaryCompany ? [res?.data?.original?.associations?.primaryCompany] : []
        let allCompanies = res?.data?.original?.associations?.companies ? res?.data?.original?.associations?.companies : []
        setCompanyAssociations([...primaryCompany, ...allCompanies])
        // setCompanyAssociations(res?.data?.original?.associations?.companies ? res?.data?.original?.associations?.companies : [])
        setContactAssociations(res?.data?.original?.associations?.contacts ? res?.data?.original?.associations?.contacts : [])
        setDealAssociations(res?.data?.original?.associations?.deals ? res?.data?.original?.associations?.deals : [])
        setDealsData(res?.data?.original?.Deals)
        // setDealsPrimaryCompany(res?.data?.original?.associations?.primaryCompany?.results?.[0] ? res?.data?.original?.associations?.primaryCompany?.results?.[0] : null)
        setDealsPrimaryCompany(res?.data?.original?.associations?.primaryCompany ? res?.data?.original?.associations?.primaryCompany : null)
      }
    }).catch(() => { setDealsData(null) })
      .finally(() => {
        setShowLoader({ contacts: false, companies: false, deals: false })
        setDealsLoading(false)
      })
  }

  useEffect(() => {
    if (id && callApi) {
      getSingleDealData(id, true)
      getContactOwners()
      getDealsProps()
      getDealsPipelines()
      setNotesLoading(true)
      getNotes('deal', id).finally(() => { setNotesLoading(false) })
    }
  }, [id, callApi])

  useEffect(() => {
    if (contactOwners) {
      let users = contactOwners?.map((item) => ({
        id: item.id,
        value: `${item?.firstName ? item?.firstName : ""} ${item?.lastName ? item?.lastName : ""}`,
      }))
      setTagList(users)
    } else {
      setTagList([])
    }
  }, [contactOwners])

  useEffect(() => {
    if (dealsData) {
      Object.keys(initialValues).forEach((field) => {
        setFieldValue(field, dealsData?.properties?.[field] ? dealsData?.properties?.[field] : "")
      })
      setCloseDate(dealsData?.properties?.closedate ? parseDateTimeString(dealsData?.properties?.closedate, 6) : null)
      setDealStage(dealsData?.properties?.dealstage)
      let stages = dealsPipelines?.find((item) => item.id == dealsData?.properties?.pipeline)
      setStageOptions(stages?.stages ? stages?.stages : [])
    }
  }, [dealsData, setFieldValue, dealsPipelines])

  const getName = (data) => {
    let name = `${data?.properties?.dealname ? data?.properties?.dealname : ""}`.trim()
    return name ? name : '--'
  }

  // delete button handler
  const deleteDealHandler = async (e, data) => {
    let msg = `You're about to delete the deal ${data?.properties?.dealname ? data?.properties?.dealname : ''}. Once deleted, this deal can be restored for up to 90 days.`
    let heading = `Delete deal?`
    let isConfirm = await confirmDeleteWindow(msg, heading)
    if (isConfirm) {
      if (data) {
        deleteHSDeal(data?.id).then(() => {
          navigate("/hubspot/deals")
        }).catch((err) => { })
      }
    }
  }

  const onCreateNoteButtonClick = () => {
    setOpenCreateNote(true)
  }

  const onCreateNoteCancelButtonClick = (load = false, item) => {
    if (item?.data?.notes) {
      setNotes((prev) => [item?.data?.notes, ...prev])
    }
    // if (load) {
    //   getNotes('deal', id);
    // }
    setOpenCreateNote(false)
  }

  const fetchNotes = (load = false) => {
    if (load) {
      getNotes('deal', id);
    }
    setOpenCreateNote(false)
  }

  const fetchNext = async (afterId) => {
    if (afterId) {
      return fetchNextNotes(afterId, 'deal', id)
    }
  }

  const deleteNoteButtonHandler = async (deleteId) => {
    let msg = `Are you sure you want to delete this note?`
    let isConfirm = await confirmDeleteWindow(msg)
    if (isConfirm) {
      if (deleteId) {
        deleteHSNotes(deleteId).then(() => {
          getNotes('deal', id)
        }).catch((err) => { })
      }
    }
  }

  const onUpdateHandler = (load) => {
    if (load) {
      getSingleDealData(id)
    }
  }

  const onDateChangeHandler = (date, dateString) => {
    setCloseDate(date)
    let data = { closedate: convertDateToTimestamp(dateString) }
    updateHSDeal(id, data).then((res) => {
      if(res.data?.original?.code == '403'){
        let message = res.data?.original?.error || ""
        sendAlert({ type: 'error', content: <ErrorMessage heading={'Permission denied'} message={message} /> }) 
      } else {
      sendAlert({ content: <DealCloseDateEditMessage date={res?.data?.properties?.closedate} /> })
      getSingleDealData(id)
      }
    })
  }

  const onDealStageChange = (value) => {
    setDealStage(value)
    let data = { dealstage: value }
    updateHSDeal(id, data).then((res) => {
      if(res.data?.original?.code == '403'){
        let message = res.data?.original?.error || ""
        sendAlert({ type: 'error', content: <ErrorMessage heading={'Permission denied'} message={message} /> }) 
      } else {
      sendAlert({ content: 'Success. Your changes were updated.' })
      getSingleDealData(id)
      }
    })
  }

  const sendAlert = ({ type, content, className }) => {
    if (content) {
      messageApi.open({
        type: type || 'success',
        content: content,
        className: `alert-message ${className ? className : ''}`,
      });
    }
  }

  const getContactName = (data) => {
    let name = `${data?.properties?.firstname ? data?.properties?.firstname : ""} ${data?.properties?.lastname ? data?.properties?.lastname : ""}`.trim()
    return name ? name : data?.properties?.email
  }

  const contactAssociationRemove = async (e, data) => {
    let msg = `${getContactName(data)} will no longer be associated with ${getName(dealsData)}`
    return await removeHandler(msg, 'Remove association')
  }

  const dealAssociationRemove = async (e, data) => {
    let msg = `${getName(data)} will no longer be associated with ${getName(dealsData)}`
    return await removeHandler(msg, 'Remove association')
  }

  const getNameOrDomain = (data) => {
    let name = `${data?.properties?.name ? data?.properties?.name : ""}`.trim()
    return name ? name : data?.properties?.domain ? data?.properties?.domain : '--'
  }

  const companyAssociationRemove = async (e, data) => {
    let msg = `${getNameOrDomain(data)} will no longer be associated with ${getName(dealsData)}`
    return await removeHandler(msg, 'Remove association')
  }

  const removeHandler = async (msg, heading) => {
    return await confirmDeleteWindow(msg, heading)
  }

  const handleSetPrimary = async (e, data, pk, type, section, associate_to, dealData, associationData) => {
    let heading = `Set ${getNameOrDomain(data)} as primary`
    let msg = pk ? `Replace ${getNameOrDomain(associationData)} as primary` : `Confirm that you want ${getNameOrDomain(data)} as the primary`
    return await confirmSetPrimaryWindow(msg, heading, section, pk ? true : false)
  }

  const handleRemovePrimary = async (e, data, primaryKey, companies) => {
    let options = companies?.map((item) => {
      let label = item?.properties?.name ? item?.properties?.name : item?.properties?.domain
      return {
        label: label ? label : '--',
        value: item?.id
      }
    })
    let filteredOpt = options?.filter((item) => { return item?.value != primaryKey })
    let heading = `Remove ${getNameOrDomain(data)} as primary`
    let subHeading = `${getNameOrDomain(data)} was the primary company for ${getName(dealsData)}. Optionally choose a new primary company for this deal before removing this one.`
    return await handlePrimaryAssocoationRemove(`Primary company for this deal`, heading, primaryKey, filteredOpt ? filteredOpt : [], subHeading)
  }

  const handleConfirm = () => {
    confirmCallback({ confirmed: true, value: selectedValue })
    setShowModal(false);
    setModalMessage({})
  };

  // Handle cancellation (no)
  const handleCancel = () => {
    confirmCallback({ confirmed: false, value: null })
    setShowModal(false);
    setModalMessage({})
  };

  const handlePrimaryAssocoationRemove = async (msg, heading, primaryKey, dropDown, subHeading, warning) => {
    let dropdownList = dropDown ? dropDown : []
    return new Promise((resolve) => {
      setSelectedValue("")
      setDropdown(dropdownList)
      setModalMessage({ msg, heading, subHeading, warning });
      setConfirmCallback(() => resolve);
      setShowModal(true);
    });
  }

  useEffect(() => {
    if (userData?.email) {
      let payload = {
        email: userData?.email
      }
      checkHubSpotAccess(payload).then((res) => {
        setHasHsAccess(true)
        setCallApi(true)
      }).catch((err) => {
        setHasHsAccess(false)
        setNotesLoading(false)
        setDealsLoading(false)
      })
    }
  }, [userData])

  const getNameForDeal = (data) => {
    let name = `${data?.properties?.name ? data?.properties?.name : ""}`.trim()
    return name ? name : data?.properties?.domain ? data?.properties?.domain : ''
  }

  const handleRemovePrimaryAssociation = async (e, data, primaryKey, companies) => {
    let options = companies?.map((item) => {
      let label = item?.properties?.name ? item?.properties?.name : item?.properties?.domain
      return {
        label: label ? label : '--',
        value: item?.id
      }
    })
    let filteredOpt = options?.filter((item) => { return item?.value != primaryKey })
    let heading = `Remove ${getNameOrDomain(data)} as primary`
    let warning = `${getNameOrDomain(data)} will no longer be associated with ${getName(dealsData)}`
    let subHeading = `${getNameOrDomain(data)} was the primary company for ${getName(dealsData)}. Optionally choose a new primary company for this deal before removing this one.`
    return await handlePrimaryAssocoationRemove(`Primary company for this deal`, heading, primaryKey, filteredOpt ? filteredOpt : [], subHeading, warning)
  }

  const onContactPreviewClick = (item) => {
    if (item) {
      setSelectedContactPreview(item)
      setShowContactDrawer(true)
      setTimeout(() => {
        setShowCompanyDrawer(false)
        setSelectedCompanyPreview(null)
        setSelectedDealPreview(null)
        setShowDealDrawer(false)
      }, 250)
    }
  }

  const onContactPreviewClose = () => {
    getSingleDealData(id)
    setSelectedContactPreview(null)
  }

  const handlePrimaryAssociationContactRemove = async (msg, heading, primaryKey, dropDown, subHeading) => {
    let dropdownList = dropDown ? dropDown : []
    return new Promise((resolve) => {
      setSelectedValue("")
      setDropdown(dropdownList)
      setModalMessage({ msg, heading, subHeading });
      setConfirmCallback(() => resolve);
      setShowModalOne(true);
    });
  }

  const onCompanyPreviewClick = (item) => {
    if (item) {
      setSelectedCompanyPreview(item)
      setShowCompanyDrawer(true)
      setTimeout(() => {
        setShowContactDrawer(false)
        setSelectedContactPreview(null)
        setSelectedDealPreview(null)
        setShowDealDrawer(false)
      }, 250)
    }
  }

  const onCompanyPreviewClose = () => {
    getSingleDealData(id)
    setSelectedCompanyPreview(null)
  }

  const onDealPreviewClick = (item) => {
    setSelectedDealPreview(item)
    setShowDealDrawer(true)
    setTimeout(() => {
      setShowContactDrawer(false)
      setSelectedContactPreview(null)
      setShowCompanyDrawer(false)
      setSelectedCompanyPreview(null)
    }, 250)
  }

  const onDealPreviewClose = () => {
    getSingleDealData(id)
    setSelectedDealPreview(null)
  }

  const handleDealPrimaryAssociationRemove = async (msg, heading, primaryKey, dropDown, subHeading, warning) => {
    let dropdownList = dropDown ? dropDown : []
    return new Promise((resolve) => {
      setSelectedValue("")
      setDropdown(dropdownList)
      setModalMessage({ msg, heading, subHeading, warning });
      setConfirmCallback(() => resolve);
      setShowModal(true);
    });
  }

  return (
    <>
      {contextHolder}
      <div style={{ paddingTop: '60px', background: '#F5F8FA', width: '100%' }}>
        <div className='hubspot-section hubspot-section-details'>
          {hasHsAccess ? <div className='column'>
            <div className='personal-info-col'>
              <div className='back-arrow'>
                <IoChevronBackSharp onClick={() => { navigate("/hubspot/deals") }} />
                <NavLink to={'/hubspot/deals'}>Deals</NavLink>
              </div>
              {dealsLoading ? <div className='d-flex justify-content-center align-items-center p-1 my-5'>
                <Spin className="text-white" indicator={<LoadingOutlined style={{ fontSize: '36px', fill: '#2c0036', color: '#2c0036' }} spin />} />
              </div> : <><div className='personal-info-wrap'>

                <div className='edit-details-wrap deals-details-wrap'>
                  <div className='personal-info'>
                    <h6>
                      {getName(dealsData)}
                    </h6>
                    <p>Amount: {`${dealsData?.properties?.amount ? formatCurrency(dealsData?.properties?.amount, false) : '--'}`}</p>
                    <div className='closedate'>
                      <label style={{ display: "block" }}>Close Date</label>
                      <DatePicker
                        name='closedate'
                        format="MM/DD/YYYY"
                        className='myDatePicker'
                        onChange={onDateChangeHandler}
                        value={closeDate ? dayjs(closeDate, "MM/DD/YYYY") : ""}
                      />
                    </div>
                    <div className='appointment-date'>
                      <label style={{ display: "block" }}>Stage: </label>
                      <AppointmentDropdown
                        options={[...sortObjectsByAttribute(stageOptions, 'displayOrder').map(({ id, label }) => ({ value: id, label: label }))]}
                        onSelect={onDealStageChange}
                        fieldValue={dealStage}
                        placeholder='Select a stage'
                      />
                    </div>
                  </div>
                  <EditDealTooltip record={dealsData} id={id} onUpdate={onUpdateHandler} sendMessage={sendAlert} />
                </div>
              </div>
                <div className='main-btn-wrap'>
                  <div className='delete-btn button-wrap' onClick={onCreateNoteButtonClick}>
                    <div className='icon'> <FaRegEdit /></div>
                    <p>Note</p>
                  </div>
                  <div className='delete-btn button-wrap' onClick={(e) => deleteDealHandler(e, dealsData)}>
                    <div className='icon'> <RiDeleteBin6Line /></div>
                    <p>Delete</p>
                  </div>
                </div></>}
              <div className='form-wrap'>
                <h5>About this deal</h5>
                <div>
                  <SearchDropdown
                    label="Deal Owner"
                    options={[
                      { label: "No Owner", value: 'default' },
                      ...sortByConcatenatedString(contactOwners, ['firstName', 'lastName']).map(({ id, firstName, lastName, email, archived }) => ({
                        value: id,
                        label: `${firstName ? firstName : ""} ${lastName ? lastName : ""}${archived ? ' (Deactivated/Removed) ' : ' '}${email ? "(" + email + ")" : ""}`.trim(),
                        archived: archived ? archived : false
                      }))]}
                    // placeholder="Choose..."
                    placeholder="Select an owner"
                    onSelect={(value) => setFieldValue('hubspot_owner_id', value)}
                    initialValue={values?.hubspot_owner_id}
                  />
                  {(touched.hubspot_owner_id && errors.hubspot_owner_id) ? <span className='ms-2 text-danger'>{errors.hubspot_owner_id}</span> : null}
                </div>
                <div>
                  <FloatingLabel label='Last Contacted' className='custom-select'>
                    <Form.Control
                      type="text"
                      value={dealsData?.properties?.notes_last_contacted ? parseDateTimeString(dealsData?.properties?.notes_last_contacted, 16) : '--'}
                      disabled={true}
                    />
                  </FloatingLabel>
                </div>
                <div>
                  <SearchDropdown
                    label="Deal Type"
                    options={[
                      { label: "Select a type", value: "" },
                      ...sortObjectsByAttribute(dealTypes, 'displayOrder')
                    ]}
                    // placeholder="Choose..."
                    placeholder="Select a type"
                    onSelect={(value) => setFieldValue('dealtype', value)}
                    initialValue={values?.dealtype}
                  />
                  {(touched.dealtype && errors.dealtype) ? <span className='ms-2 text-danger'>{errors.dealtype}</span> : null}
                </div>
                <div>
                  <SearchDropdown
                    label="Priority"
                    options={[
                      { label: "Select a priority", value: "" },
                      ...sortObjectsByAttribute(dealPriority, 'displayOrder')
                    ]}
                    // placeholder="Choose..."
                    placeholder="Select a priority"
                    onSelect={(value) => setFieldValue('hs_priority', value)}
                    initialValue={values?.hs_priority}
                  />
                  {(touched.hs_priority && errors.hs_priority) ? <span className='ms-2 text-danger'>{errors.hs_priority}</span> : null}
                </div>
                <div>
                  <FloatingLabel label='Record Source' className='custom-select'>
                    <Form.Select
                      aria-label="Record Source"
                      name='hs_object_source'
                      value={dealsData?.properties?.hs_object_source ? dealsData?.properties?.hs_object_source : ""}
                      disabled={true}
                    >
                      <option value="" key="0">--</option>
                      {hsObjectSource?.map((item) => {
                        return <option value={item.id} key={item.id}>{item.name}</option>
                      })}
                    </Form.Select>
                  </FloatingLabel>
                </div>
                <div className='d-flex align-items-center justify-content-center'>
                  <Button disabled={isSaving} type="button" onClick={handleSubmit}>Save {isSaving && <Spin className="ms-2 text-white" indicator={<LoadingOutlined style={{ fontSize: '18px', fill: '#fff' }} spin />} />}</Button>
                </div>
              </div>
            </div>
            <div className='note-overview-col'>
              <div className='note-header-wrap'>
                <h5>Overview</h5>
                <div className='note-head'>
                  <p>Notes</p>
                  <Button className="headBtn" onClick={onCreateNoteButtonClick}> <FaIcons.FaPlus />Create Note</Button>
                </div>
              </div>
              <AccordionNote loading={notesLoading} tagList={tagList} items={notes} owners={contactOwners} paging={notesPaging} fetchNext={fetchNext} fetchNotes={fetchNotes} onDelete={deleteNoteButtonHandler} type={'contact'} associatedObjectId={id} />
            </div>
            <div className='association-overview-col'>
              <div className='association-wrapper'>
                <ContactAssociation
                  id={dealsData?.id}
                  section={'deals'}
                  type={'deal_to_contact'}
                  associate_to={'contacts'}
                  associatedContacts={contactAssociations ? contactAssociations : []}
                  associatedContactIds={extractIds(contactAssociations ? contactAssociations : [])}
                  fetchData={getSingleDealData}
                  sendMessage={sendAlert}
                  handleRemove={contactAssociationRemove}
                  showLoader={showLoader}
                  setShowLoader={setShowLoader}
                  onContactPreviewClick={onContactPreviewClick}
                />
                <div className='border-bottom'></div>
                <CompanyAssociation
                  id={dealsData?.id}
                  section={'deals'}
                  type={'deal_to_company'}
                  associate_to={'companies'}
                  associatedCompanies={companyAssociations ? companyAssociations : []}
                  associatedCompanyIds={extractIds(companyAssociations ? companyAssociations : [])}
                  fetchData={getSingleDealData}
                  sendMessage={sendAlert}
                  handleRemove={companyAssociationRemove}
                  handleSetPrimary={(e, data, pk, type, section, associate_to) => { return handleSetPrimary(e, data, pk, type, section, associate_to, dealsData, dealsPrimaryCompany) }}
                  primaryKey={dealsPrimaryCompany?.id}
                  handleRemovePrimary={handleRemovePrimary}
                  showLoader={showLoader}
                  setShowLoader={setShowLoader}
                  primaryCompanyName={`${getNameForDeal(dealsPrimaryCompany)}`.trim()}
                  name={getName(dealsData)}
                  handleRemovePrimaryAssociation={handleRemovePrimaryAssociation}
                  onCompanyPreviewClick={onCompanyPreviewClick}
                />
                <div className='border-bottom'></div>
                <DealAssociation
                  id={dealsData?.id}
                  section={'deals'}
                  type={'deal_to_deal'}
                  associate_to={'deals'}
                  associatedDeals={dealAssociations ? dealAssociations : []}
                  associatedDealIds={extractIds(dealAssociations ? dealAssociations : [])}
                  fetchData={getSingleDealData}
                  sendMessage={sendAlert}
                  handleRemove={dealAssociationRemove}
                  primaryKey={dealsPrimaryCompany?.id}
                  name={`${getNameForDeal(dealsPrimaryCompany)}`.trim()}
                  showLoader={showLoader}
                  setShowLoader={setShowLoader}
                  onDealPreviewClick={onDealPreviewClick}
                />
              </div>
            </div>
          </div> : <PermissionMessage />}
        </div>
      </div>
      {hasHsAccess && <>
        {openCreateNote && <CreateNoteModal tagList={tagList} onCancel={onCreateNoteCancelButtonClick} type={'deal'} associatedObjectId={id} owners={contactOwners} />}
        {showContactDrawer && <ContactOffCanvas
          record={selectedContactPreview}
          show={showContactDrawer}
          setShow={setShowContactDrawer}
          afterDelete={() => { }}
          onClose={onContactPreviewClose}
          viewRecord={(e, item) => {
            e.preventDefault()
            if (item) {
              navigate(`/hubspot/contacts/${item.id}`)
            }
          }}
          openFileUpload={() => { }}
          openFileRemove={() => { }}
          createNote={() => { }}
          sendMessage={sendAlert}
          removeContactAssociation={removeHandler}
          handleSetPrimary={handleSetPrimary}
          removePrimaryAssociation={handlePrimaryAssociationContactRemove}
          onContactPreviewClick={onContactPreviewClick}
          onCompanyPreviewClick={onCompanyPreviewClick}
          onDealPreviewClick={onDealPreviewClick}
        />}
        {showCompanyDrawer && <CompanyOffCanvas
          record={selectedCompanyPreview}
          show={showCompanyDrawer}
          setShow={setShowCompanyDrawer}
          afterDelete={() => { }}
          onClose={onCompanyPreviewClose}
          viewRecord={(e, item) => {
            if (item?.id) {
              e.preventDefault()
              navigate(`/hubspot/companies/${item.id}`)
            }
          }}
          openFileUpload={() => { }}
          openFileRemove={() => { }}
          sendMessage={sendAlert}
          removeContactAssociation={removeHandler}
          onContactPreviewClick={onContactPreviewClick}
          onCompanyPreviewClick={onCompanyPreviewClick}
          onDealPreviewClick={onDealPreviewClick}
        />}
        {showDealDrawer && <DealsOffCanvas
          record={selectedDealPreview}
          show={showDealDrawer}
          setShow={setShowDealDrawer}
          onClose={onDealPreviewClose}
          viewRecord={(e, item) => {
            e.preventDefault()
            if (item.id) {
              navigate(`/hubspot/deals/${item.id}`)
            }
          }}
          sendMessage={sendAlert}
          removeContactAssociation={removeHandler}
          handleSetPrimary={handleSetPrimary}
          removePrimaryAssociation={handleDealPrimaryAssociationRemove}
          onContactPreviewClick={onContactPreviewClick}
          onCompanyPreviewClick={onCompanyPreviewClick}
          onDealPreviewClick={onDealPreviewClick}
        />}
        {<CustomHSDeletePrimary
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          show={showModalOne}
          message={modalMessage}
          onConfirm={() => {
            confirmCallback({ confirmed: true, value: selectedValue })
            setShowModalOne(false);
            setModalMessage({})
          }}
          onCancel={() => {
            confirmCallback({ confirmed: false, value: null })
            setShowModalOne(false);
            setModalMessage({})
          }}
          dropdown={dropdown}
        />}
        {<CustomHSDeleteDealsPrimary
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          show={showModal}
          message={modalMessage}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          dropdown={dropdown}
        />}
        {<DeleteHSConfirmModal />}
        {<SetPrimaryConfirmModal />}
      </>}
    </>
  )
}

export default HubspotDealsDetails