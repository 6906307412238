/* eslint-disable eqeqeq */
import { IoIosCloseCircle } from "react-icons/io";
import Button from './Button';
import { Tooltip } from 'antd';
import { IoMdInformationCircle } from "react-icons/io";
import SearchDropdown from "./SearchDropdown";
import { sortByConcatenatedString } from "../../../Utils/sortFunctions";
import { Form } from "react-bootstrap";
import { useState } from "react";

export const CustomModal = ({ show, message, onConfirm, onCancel }) => {
    if (!show) {
        return null;
    }

    return (<div className={`centerpopups bill-modal delete-modal`}>
        <div className='centerpopups'>
            <div className='popups d-flex justify-content-center align-items-center w-100'>
                <div className='addpopups customer-doc-popup delete-warn'>
                    <div className='mb-auto pophead d-flex align-items-center justify-content-between'>
                        <div>Confirm Deletion</div>
                        <div className='myIcon' type='button' onClick={onCancel}>
                            <IoIosCloseCircle style={{ width: '28px' }} />
                        </div>
                    </div>
                    <div className='popBody p-3 customer-body'>
                        <p>{message || "Are you sure you want to delete this item?"}</p>
                    </div>
                    <div className='mt-auto popfoot w-100 p-2'>
                        <div className='d-flex align-items-center justify-content-center'>
                            <Button type="button" className="mx-4 cclBtn" onClick={onCancel}>No</Button>
                            <Button type="button" className="yes-button" onClick={onConfirm}>Yes</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="blurBg"></div>
        </div>
    </div>);
};

export const SetPrimaryModal = ({ show, message, heading, onConfirm, onCancel, section, showInputBox }) => {
    const [isChecked, setIsChecked] = useState(false);

    if (!show) {
        return null;
    }

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    return (<div className={`centerpopups bill-modal delete-modal set-primary-modal`}>
        <div className='centerpopups'>
            <div className='popups d-flex justify-content-center align-items-center w-100'>
                <div className='addpopups customer-doc-popup delete-warn'>
                    <div className='mb-auto pophead d-flex align-items-center justify-content-between'>
                        <div>{heading}</div>
                        <div className='myIcon' type='button' onClick={onCancel}>
                            <IoIosCloseCircle style={{ width: '28px' }} />
                        </div>
                    </div>
                    <div className='popBody p-3 customer-body'>
                        {(!showInputBox && section == 'deals') ? <p className={`message ${!showInputBox ? 'message-weight-normal' : ''}`}>{message}</p> : <p className="message">{section == 'deals' ? 'Primary company for this deal' : 'Primary company for this contact'}</p>}
                        {(showInputBox || section == 'contacts') && <Form.Check
                            type="checkbox"
                            label={message}
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                        />}
                    </div>
                    <div className='mt-auto popfoot w-100 p-2'>
                        <div className='d-flex align-items-center justify-content-center'>
                            <Button type="button" className="mx-4 cclBtn" onClick={onCancel}>No</Button>
                            {(!showInputBox && section == 'deals') ? <Button type="button" className="yes-button" onClick={onConfirm}>Yes</Button> : <Button type="button" className="yes-button" disabled={!isChecked} onClick={onConfirm}>Yes</Button>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="blurBg"></div>
        </div>
    </div>);
};

export const CustomDeleteModal = ({ show, message, onConfirm, onCancel, heading }) => {
    if (!show) {
        return null;
    }

    return (<div className={`centerpopups bill-modal delete-modal`}>
        <div className='centerpopups'>
            <div className='popups d-flex justify-content-center align-items-center w-100'>
                <div className='addpopups customer-doc-popup delete-warn'>
                    <div className='mb-auto pophead d-flex align-items-center justify-content-between'>
                        <div>{heading ? heading : "Confirm Deletion"}</div>
                        <div className='myIcon' type='button' onClick={onCancel}>
                            <IoIosCloseCircle style={{ width: '28px' }} />
                        </div>
                    </div>
                    <div className='popBody p-3 customer-body'>
                        <p>{message || "Are you sure you want to delete this item?"}</p>
                    </div>
                    <div className='mt-auto popfoot w-100 p-2'>
                        <div className='d-flex align-items-center justify-content-center'>
                            <Button type="button" className="mx-4 cclBtn" onClick={onCancel}>No</Button>
                            <Button type="button" className="yes-button" onClick={onConfirm}>Yes</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="blurBg"></div>
        </div>
    </div>);
};

export const CustomHSDeleteModal = ({ show, onConfirm, onCancel, deleteType, onDeleteTypeChange, onSelectChange, contactOwners, message, sendEmail }) => {
    if (!show) {
        return null;
    }

    return (<div className={`centerpopups bill-modal delete-modal delete-modal-contact`}>
        <div className='centerpopups'>
            <div className='popups d-flex justify-content-center align-items-center w-100'>
                <div className='addpopups customer-doc-popup delete-warn'>
                    <div className='mb-auto pophead d-flex align-items-center justify-content-between'>
                        <div>Delete contact?</div>
                        <div className='myIcon' type='button' onClick={onCancel}>
                            <IoIosCloseCircle style={{ width: '28px' }} />
                        </div>
                    </div>
                    <div className='popBody p-3 customer-body delete-contact'>
                        <p className="instruction">{message}</p>
                        <div className='delete-options'>
                            <div className="radio-btn">
                                <input
                                    type="radio"
                                    id="restorable"
                                    value="restorable"
                                    checked={deleteType === 'restorable'}
                                    onChange={onDeleteTypeChange}
                                />
                                <div>
                                    <label htmlFor="restorable">Restorable delete
                                        <Tooltip title="Temporarily stores deleted contacts in the recycle bin.">
                                            <IoMdInformationCircle />
                                        </Tooltip></label>
                                    <p className="sub-text">Delete the contact with the option to restore it for up to 90 days.</p>
                                </div>
                            </div>
                            <div className="radio-btn">
                                <input
                                    type="radio"
                                    id="permanent"
                                    value="permanent"
                                    checked={deleteType === 'permanent'}
                                    onChange={onDeleteTypeChange}
                                />
                                <div>
                                    <label htmlFor="permanent">Permanent delete
                                        <Tooltip title="Use this option when a contact requests permanent deletion.
">
                                            <IoMdInformationCircle />
                                        </Tooltip>
                                    </label>
                                    <p className="sub-text">Delete all information associated with this contact. They can’t be added back, and this can’t be undone.</p>
                                </div>
                            </div>
                            {deleteType == 'permanent' && <div>
                                <SearchDropdown
                                    label="Send Confirmation Email"
                                    tooltip="Send a permanent delete confirmation email to any HubSpot user."
                                    options={[
                                        ...sortByConcatenatedString(contactOwners, ['firstName', 'lastName']).map(({ id, firstName, lastName, email }) => ({
                                            value: id,
                                            label: `${firstName ? firstName : ""} ${lastName ? lastName : ""} ${email ? "(" + email + ")" : ""}`.trim()
                                        }))]}
                                    placeholder="Search name or email"
                                    onSelect={onSelectChange}
                                    initialValue={sendEmail}
                                />
                            </div>}
                        </div>
                    </div>
                    <div className='mt-auto popfoot w-100 p-2'>
                        <div className='d-flex align-items-center justify-content-center'>
                            <Button type="button" className="mx-4 cclBtn" onClick={onCancel}>Cancel</Button>
                            <Button type="button" className="yes-button" onClick={onConfirm}>Delete contact</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="blurBg"></div>
        </div>
    </div>);
};

export function confirmDelete(modelName) {
    const isConfirm = window.confirm(`Are you sure you want to delete this ${modelName ? modelName : ""}?`)
    return isConfirm
}

export function confirmWindow(msg) {
    const isConfirm = window.confirm(msg)
    return isConfirm
}

export function WarningDialog({ okClick, noClick, title, description }) {
    return (<div className={`centerpopups bill-modal`}>
        <div className='centerpopups'>
            <div className='popups d-flex justify-content-center align-items-center w-100'>
                <div className='addpopups customer-doc-popup'>
                    <div className='mb-auto pophead d-flex align-items-center justify-content-between'>
                        <div>{title}</div>
                        <div className='myIcon' type='button' onClick={noClick}>
                            <IoIosCloseCircle style={{ width: '28px' }} />
                        </div>
                    </div>
                    <div className='popBody p-3 customer-body'>
                        <p>{description}</p>
                    </div>
                    <div className='mt-auto popfoot w-100 p-2'>
                        <div className='d-flex align-items-center justify-content-center'>
                            <Button type="button" className="mx-4 cclBtn" onClick={noClick}>No</Button>
                            <Button type="button" onClick={okClick}>Yes</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="blurBg"></div>
        </div>
    </div>)
}

export const CustomHSDeletePrimary = ({ show, onConfirm, onCancel, dropdown, selectedValue, setSelectedValue, message }) => {
    if (!show) {
        return null;
    }

    return (<div className={`centerpopups bill-modal delete-modal delete-modal-contact delete-primary-company`}>
        <div className='centerpopups'>
            <div className='popups d-flex justify-content-center align-items-center w-100'>
                <div className='addpopups customer-doc-popup delete-warn'>
                    <div className='mb-auto pophead d-flex align-items-center justify-content-between'>
                        <div>{message?.heading}</div>
                        <div className='myIcon' type='button' onClick={onCancel}>
                            <IoIosCloseCircle style={{ width: '28px' }} />
                        </div>
                    </div>
                    <div className='popBody p-3 customer-body delete-contact'>
                        {message?.subHeading && <p className="instruction sub">{message?.subHeading}</p>}
                        <p className="instruction">{message?.msg}</p>
                        <div className='delete-options'>
                            <div>
                                <SearchDropdown
                                    label={''}
                                    options={dropdown}
                                    placeholder="Select a company"
                                    onSelect={(value) => { setSelectedValue(value) }}
                                    initialValue={selectedValue}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='mt-auto popfoot w-100 p-2'>
                        <div className='d-flex align-items-center justify-content-center'>
                            <Button type="button" className="mx-4 cclBtn" onClick={onCancel}>Cancel</Button>
                            <Button type="button" className="yes-button" disabled={!selectedValue} onClick={onConfirm}>{message?.subHeading ? 'Remove' : 'Update'}</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="blurBg"></div>
        </div>
    </div>);
};

export const CustomHSDeleteDealsPrimary = ({ show, onConfirm, onCancel, dropdown, selectedValue, setSelectedValue, message }) => {
    if (!show) {
        return null;
    }

    return (<div className={`centerpopups bill-modal delete-modal delete-modal-contact delete-primary-company`}>
        <div className='centerpopups'>
            <div className='popups d-flex justify-content-center align-items-center w-100'>
                <div className='addpopups customer-doc-popup delete-warn'>
                    <div className='mb-auto pophead d-flex align-items-center justify-content-between'>
                        {message?.heading && <div>{message?.heading}</div>}
                        <div className='myIcon' type='button' onClick={onCancel}>
                            <IoIosCloseCircle style={{ width: '28px' }} />
                        </div>
                    </div>
                    <div className='popBody p-3 customer-body delete-contact'>
                        {message?.warning && <p className="instruction sub-instruction mb-2">{message?.warning}</p>}
                        {message?.subHeading && <p className="instruction sub-instruction">{message?.subHeading}</p>}
                        {message?.msg && <p className="instruction">{message?.msg}</p>}
                        <div className='delete-options'>
                            <div>
                                <SearchDropdown
                                    label={''}
                                    options={dropdown}
                                    placeholder="Select a company (optional)"
                                    onSelect={(value) => { setSelectedValue(value) }}
                                    initialValue={selectedValue}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='mt-auto popfoot w-100 p-2'>
                        <div className='d-flex align-items-center justify-content-center'>
                            <Button type="button" className="mx-4 cclBtn" onClick={onCancel}>Cancel</Button>
                            <Button type="button" className="yes-button" onClick={onConfirm}>{message?.warning ? 'Remove' : 'Update'}</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="blurBg"></div>
        </div>
    </div>);
};