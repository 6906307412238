/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import { sortObjectsByAttribute } from '../../../../../Utils/sortFunctions'
import SearchDropdown from '../../SearchDropdown'
import { FloatingLabel, Form } from 'react-bootstrap'
import Button from '../../Button'
import MyTooltip from '../../MyTooltip'
import { DatePicker, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import MultiselectDropdown from '../../MultiselectDropdown'
import { amountCheck, cleanAnnualRevenue } from '../../../../../Utils/helpers'
import dayjs from "dayjs"
import 'dayjs/locale/en';
import { IoMdInformationCircle } from "react-icons/io";
import { timelineOptions } from '../../../../../Utils/staticdata'
import { useDealsPipelines, useDealsProps } from '../../../../hooks/deals'
import { useContactList } from '../../../../hooks/contacts'
import { useCompanyList } from '../../../../hooks/company'
import { useFormik } from 'formik'
import { hsDealSchema } from '../../../../../Utils/validation'
import { convertDateToTimestamp } from '../../../../../Utils/dateFormat'
import { addHSDeal, editAssociation } from '../../../../../API/authCurd'
import { EditMessage, ErrorMessage } from '../../../../hooks/hubspot'

const getDealContactLabel = (contacts, arr) => {
    if (arr?.length == 1) {
        let contact = contacts?.find((item) => item.value == arr?.[0])
        return `Add timeline activity from ${contact?.name} to this deal `
    } else if (arr?.length > 1) {
        return `Add timeline activity from selected contacts to this deal `
    } else {
        return `Add timeline activity from this contact `
    }
}

const getDealCompanyLabel = (company, selected) => {
    if (selected) {
        let data = company?.find((item) => selected == item?.value)
        return `Add timeline activity from ${data?.label} to this deal `
    } else {
        return `Add timeline activity from this company `
    }
}

const disableDatesAfterToday = (current) => {
    return current && current > dayjs().endOf('day');
};

const initialValues = {
    dealname: '',
    pipeline: 'default',
    dealstage: '',
    amount: '',
    closedate: dayjs().endOf('month').format('MM/DD/YYYY'),
    createdate: '',
    dealtype: '',
    referral_sources: '',

    associated_contacts: [],
    contact_timeline: false,
    contact_timeline_date: "",
    contact_timeline_range: "",

    associated_company: '',
    company_timeline: false,
    company_timeline_date: "",
    company_timeline_range: ""
}

const CreateDealForm = ({ sendMessage, onClose, contactId, companyPrimaryKey, section, name, id, associate_to, type }) => {
    const [isSaving, setIsSaving] = useState(false)
    const { dealTypes, getDealsProps, dealReferralSources } = useDealsProps();
    const { dealsPipelines, getDealsPipelines } = useDealsPipelines();
    const { contacts, getContacts, contactsLoading } = useContactList();
    const { companies, getCompanyList, companiesLoading } = useCompanyList();
    const [dealStages, setDealStages] = useState([])
    const [areFieldsEnabled, setAreFieldsEnabled] = useState(false);

    const { errors, resetForm, getFieldProps, handleSubmit, touched, setFieldValue, values } = useFormik({
        initialValues,
        validationSchema: hsDealSchema,
        onSubmit: (values) => {
            let data = {
                ...values,
                closedate: convertDateToTimestamp(values?.closedate),
                createdate: convertDateToTimestamp(values?.createdate),
            }
            if (data.hubspot_owner_id == 'default') { data.hubspot_owner_id = "" }
            let formattedData = {
                dealData: {
                    dealname: data?.dealname || "",
                    pipeline: data?.pipeline || "",
                    dealstage: data?.dealstage || "",
                    amount: data?.amount ? cleanAnnualRevenue(data?.amount) : "",
                    closedate: data?.closedate || "",
                    createdate: data?.createdate || "",
                    dealtype: data?.dealtype || "",
                    referral_sources: data?.referral_sources || "",
                },
                contactIds: {
                    id: data?.associated_contacts ? data?.associated_contacts : [],
                    type: "deal_to_contact"
                },
                companyId: {
                    id: data?.associated_company ? data?.associated_company : "",
                    type: "deal_to_company"
                }
            }
            setIsSaving(true)
            addHSDeal(formattedData).then((res) => {
                if (res.data?.original?.code == '403') {
                    let message = res.data?.original?.error || ""
                    sendMessage({ type: 'error', content: <ErrorMessage heading={'Permission denied'} message={message} /> })
                    setIsSaving(false)
                } else {
                if (section == 'deals') {
                    let payload = {}
                    payload.from = id
                    payload.to = res?.data?.deals?.id ? [res?.data?.deals?.id] : []
                    payload.type = type
                    payload.section = section
                    payload.associateTo = associate_to
                    setIsSaving(true)
                    editAssociation(payload).then((res) => {
                        sendMessage({ content: <EditMessage msg={'Your deal was created.'} /> })
                        closeButtonHandler()
                    }).finally(() => { setIsSaving(false) })
                } else {
                    sendMessage({ content: <EditMessage msg={'Your deal was created.'} /> })
                    closeButtonHandler()
                    setIsSaving(false)
                }
            }
            }).catch((err) => { })
        },
    });

    useEffect(() => {
        if (values?.pipeline) {
            let data = dealsPipelines?.find((item) => item.id == values?.pipeline)
            setDealStages(data?.stages ? data?.stages : [])
            if (!values?.dealstage) {
                const fistStage = data?.stages?.find((item) => item?.displayOrder == 0)
                setFieldValue('dealstage', fistStage?.id ? fistStage?.id : "")
            }
        } else {
            setDealStages([])
        }
    }, [dealsPipelines, values?.pipeline])

    function showButtonHandler() {
        getDealsProps()
        getDealsPipelines()
        getContacts()
        getCompanyList()
    }

    useEffect(() => {
        showButtonHandler()
    }, [])

    useEffect(() => {
        if (contactId) { 
            if(section == "contacts"){
                setFieldValue('associated_contacts', [contactId]) 
            } else if(section == "companies"){
                setFieldValue('associated_company', contactId)
            }
        }
    }, [contactId, section])

    useEffect(() => {
        if (companyPrimaryKey && section == 'contacts') { 
            setFieldValue('associated_company', companyPrimaryKey)
        }
    }, [companyPrimaryKey, section])

    useEffect(() => {
        if (name) { 
            setFieldValue('dealname', name ? `${name} - New Deal` : '')
        }
    }, [name])

    function closeButtonHandler() {
        setIsSaving(false)
        onClose()
        resetForm()
    }

    useEffect(() => {
        const isPrimaryFieldValid = (values.dealname && values.pipeline && values.dealstage);
        setAreFieldsEnabled(!!isPrimaryFieldValid);
    }, [values.dealname, values.pipeline, values.dealstage]);

    return (
        <div className='form-wrap'>
            <div>
                <FloatingLabel label="Deal Name *">
                    <Form.Control
                        type="text"
                        name='dealname'
                        placeholder=''
                        {...getFieldProps("dealname")}
                    />
                </FloatingLabel>
                {(touched.dealname && errors.dealname) ? <span className='ms-2 text-danger'>{errors.dealname}</span> : null}
            </div>
            <div>
                <SearchDropdown
                    label="Pipeline *"
                    options={[...sortObjectsByAttribute(dealsPipelines, 'displayOrder').map(({ id, label }) => ({ value: id, label: label }))]}
                    placeholder="Choose..."
                    onSelect={(value) => setFieldValue('pipeline', value)}
                    initialValue={values?.pipeline}
                />
                {(touched.pipeline && errors.pipeline) ? <span className='ms-2 text-danger'>{errors.pipeline}</span> : null}
            </div>
            <div>
                <SearchDropdown
                    label="Deal Stage *"
                    options={[...sortObjectsByAttribute(dealStages, 'displayOrder').map(({ id, label }) => ({ value: id, label: label }))]}
                    placeholder="Choose..."
                    onSelect={(value) => setFieldValue('dealstage', value)}
                    initialValue={values?.dealstage}
                />
                {(touched.dealstage && errors.dealstage) ? <span className='ms-2 text-danger'>{errors.dealstage}</span> : null}
            </div>
            <div>
                <FloatingLabel label="Amount">
                    <Form.Control
                        type='text'
                        placeholder=''
                        {...getFieldProps("amount")}
                        onKeyDown={(e) => amountCheck(e)}
                    />
                </FloatingLabel>
                {(touched.amount && errors.amount) ? <span className='ms-2 text-danger'>{errors.amount}</span> : null}
            </div>
            <SearchDropdown
                label="Deal Type"
                options={[
                    { label: "Select a type", value: "" },
                    ...sortObjectsByAttribute(dealTypes, 'displayOrder')
                ]}
                // placeholder="Choose..."
                placeholder="Select a type"
                onSelect={(value) => setFieldValue('dealtype', value)}
                initialValue={values?.dealtype}
            />
            <div>
                <div className='myInputBox w-100 '>
                    <label style={{ display: "block" }}>Create Date</label>
                    <DatePicker
                        name='createdate'
                        placeholder='MM/DD/YYYY'
                        format="MM/DD/YYYY"
                        className='myDatePicker'
                        value={values.createdate ? dayjs(values.createdate, 'MM/DD/YYYY') : ''}
                        onChange={(date) => setFieldValue('createdate', date ? dayjs(date).format('MM/DD/YYYY') : '')}
                    />
                </div>
            </div>
            <div>
                <div className='myInputBox w-100 '>
                    <label style={{ display: "block" }}>Close Date</label>
                    <DatePicker
                        name='closedate'
                        placeholder='MM/DD/YYYY'
                        format="MM/DD/YYYY"
                        className='myDatePicker'
                        value={dayjs(values.closedate, 'MM/DD/YYYY')}
                        onChange={(date) => setFieldValue('closedate', date ? dayjs(date).format('MM/DD/YYYY') : '')}
                    />
                </div>
            </div>
            <div>
              <SearchDropdown
                label="Referral sources"
                options={[
                    { label: "Select a source", value: "" },
                    ...sortObjectsByAttribute(dealReferralSources, 'displayOrder')
                ]}
                // placeholder="Choose..."
                placeholder="Select a source"
                onSelect={(value) => setFieldValue('referral_sources', value)}
                initialValue={values?.referral_sources}
              />
            </div>
            {/* Deals Associations */}
            <div className='border-bottom'></div>
            <h5>Associate deal with</h5>

            <div>
                <MultiselectDropdown
                    label="Contact"
                    options={sortObjectsByAttribute(contacts ? contacts : [], 'label')}
                    onChange={(value) => { setFieldValue('associated_contacts', value) }}
                    value={values.associated_contacts}
                    placeholder={'Search'}
                    loading={contactsLoading}
                />
                <div>
                    <div className='contact_timeline'>
                        <input
                            type="checkbox"
                            name='contact_timeline'
                            id='contact_timeline'
                            {...getFieldProps("contact_timeline")}
                            disabled={!values?.associated_contacts || values?.associated_contacts?.length == 0}
                        />
                        <label
                            htmlFor='contact_timeline'
                            className={`small-text ms-2 ${!values?.associated_contacts || values?.associated_contacts?.length == 0 ? 'disabled-label' : ''}`}
                        >
                            {getDealContactLabel(contacts, values?.associated_contacts)}
                            {!values?.contact_timeline && <MyTooltip title="Timeline activity includes notes, emails, calls, tasks, and meetings.">
                                <IoMdInformationCircle />
                            </MyTooltip>}
                            {values?.contact_timeline && 'starting from'}
                        </label>
                    </div>
                    {values?.contact_timeline ? <div className={`timeline-field ${!values?.associated_contacts || values?.associated_contacts?.length == 0 ? 'disabled-field' : ''}`}>
                        <Form.Select
                            name='contact_timeline_range'
                            {...getFieldProps("contact_timeline_range")}
                        >
                            {timelineOptions?.map((item) => {
                                return <option value={item.id} key={item.id}>{item.name}</option>
                            })}
                        </Form.Select>
                        {values?.contact_timeline_range == 'CUSTOM_DATE_RANGE' && <DatePicker
                            name='contact_timeline_date'
                            format="MM/DD/YYYY"
                            className='myDatePicker timeline-field-date-picker'
                            disabledDate={disableDatesAfterToday}
                            onChange={(date) => setFieldValue('contact_timeline_date', date ? dayjs(date).format('MM/DD/YYYY') : '')}
                        />}
                    </div> : null}
                </div>
            </div>

            <div>
                <SearchDropdown
                    tooltip={'One-to-one'}
                    label="Company "
                    options={[
                        { label: "Select a company", value: "" },
                        ...sortObjectsByAttribute(companies, 'label')
                    ]}
                    // placeholder="Choose..."
                    placeholder="Select a company"
                    onSelect={(value) => setFieldValue('associated_company', value)}
                    initialValue={values?.associated_company}
                    loading={companiesLoading}
                />
                <div>
                    <div className='contact_timeline'>
                        <input
                            type="checkbox"
                            name='company_timeline'
                            id='company_timeline'
                            {...getFieldProps("company_timeline")}
                            disabled={!values?.associated_company}
                        />
                        <label
                            htmlFor='company_timeline'
                            className={`small-text ms-2 ${!values?.associated_company ? 'disabled-label' : ''}`}
                        >
                            {getDealCompanyLabel(companies, values?.associated_company)}
                            {!values?.company_timeline && <MyTooltip title="Timeline activity includes notes, emails, calls, tasks, and meetings.">
                                <IoMdInformationCircle />
                            </MyTooltip>}
                            {values?.company_timeline && 'starting from'}
                        </label>
                    </div>
                    {values?.company_timeline ? <div className={`timeline-field ${!values?.associated_company ? 'disabled-field' : ''}`}>
                        <Form.Select
                            name='company_timeline_range'
                            {...getFieldProps("company_timeline_range")}
                        >
                            {timelineOptions?.map((item) => {
                                return <option value={item.id} key={item.id}>{item.name}</option>
                            })}
                        </Form.Select>
                        {values?.company_timeline_range == 'CUSTOM_DATE_RANGE' && <DatePicker
                            name='company_timeline_date'
                            format="MM/DD/YYYY"
                            className='myDatePicker timeline-field-date-picker'
                            disabledDate={disableDatesAfterToday}
                            onChange={(date) => setFieldValue('company_timeline_date', date ? dayjs(date).format('MM/DD/YYYY') : '')}
                        />}
                    </div> : null}
                </div>
            </div>

            <div className='d-flex align-items-center justify-content-center'>
                <Button className="mx-4 cclBtn" onClick={closeButtonHandler}>Cancel</Button>
                {!areFieldsEnabled ? <MyTooltip title={'Please add all required information.'}>
                    <Button type="button" disabled={isSaving || !areFieldsEnabled} onClick={handleSubmit}>Save {isSaving && <Spin className="ms-2 text-white" indicator={<LoadingOutlined style={{ fontSize: '18px', fill: '#fff' }} spin />} />}</Button>
                </MyTooltip> :
                    <Button type="button" disabled={isSaving || !areFieldsEnabled} onClick={handleSubmit}>Save {isSaving && <Spin className="ms-2 text-white" indicator={<LoadingOutlined style={{ fontSize: '18px', fill: '#fff' }} spin />} />}</Button>}
            </div>
        </div>
    )
}

export default CreateDealForm