/* eslint-disable no-dupe-keys */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */

import React, { useContext, useEffect, useState } from "react";
import TaskUpdate from "../../popups/taskpops/TaskUpdate";
import { useLocation, useNavigate } from 'react-router-dom';
import TableBtn from "../../commonModules/UI/TableBtn";
import MyTooltip from "../../commonModules/UI/MyTooltip";
import ActivityPopups from "../../popups/taskpops/ActivityPopups";
import { deleteTask, deleteTaskActivityDocument, fecthUsersWithType, fetchActivityNotesByTaskId, getJob, getSingleTask, patchAssignedUserData, patchCustomerReview, postDuplicateTaskData, uploadTaskActivityDocument } from "../../../API/authCurd";
import { BiSolidEdit } from "react-icons/bi";
import { Table, Tooltip, message } from "antd";
import { calculatePageRange, downloadFile, getOriginalFileName, getSortOrder, moveFileToFront, saveFilterToLocal } from "../../../Utils/helpers";
import { LoadingOutlined } from '@ant-design/icons';
import EditableText from "../../EditableText ";
import TaskReview from "./TaskReview";
import * as RiIcons from "react-icons/ri";
import AssignUser from "./AssignUser";
import { isOverdue, parseDateTimeString } from "../../../Utils/dateFormat";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import { paginationInitialPage, paginationSizeChanger } from "../../../Utils/pagination";
import { BsExclamationCircleFill } from "react-icons/bs"
import { FaArrowAltCircleUp } from "react-icons/fa"
import { GlobalSearch } from "../../contexts/GlobalSearchContext";
import { AssigneeFilter, CustomerReviewFilter, JobFilter, StatusFilter, TaskFilter } from "../../FilterDropdown";
import TaskBeingWorkedOn from "./TaskBeingWorkedOn";
import { confirmWindow, WarningDialog } from "../../commonModules/UI/Dialogue";
import { JobContext } from "../../contexts/JobContext";
import UpdateForm from "../../popups/jobspopups/updateForm";
import { CustomerContext } from "../../contexts/CustomerContext";
import UpdateCust from "../../popups/custpops/UpdateCust";
import { FaSquareArrowUpRight } from "react-icons/fa6";
import CallEntryPopup from "../../popups/taskpops/CallEntryPopup";
import AssignedUserList from "./AssignedUserList";
import { taskActivityDocExtentions, taskColumns } from "../../../Utils/staticdata";
import { ActivityNotesContext } from "../../contexts/ActivityNotesContext";
import { useConfirm } from '../../hooks/use-confirm';
const paginationPerPage = 50

const TasksTable = ({ taskId, getServiceTypes, getCustomersList, isLoading, serviceTypes, paymentTerms, customerList, getTaskList, taskDetail, paginationData, getTaskListPagination, showCallEntries, filters, setFilters, isInitialCall, taskAssigneeList }) => {
  const [popUps, setPopUps] = useState(false);
  const [activepopUps, setActivePopUps] = useState(false);
  const [taskUpdateDetail, settaskUpdateDetail] = useState([]);
  const [activityNotes, setActivityNotes] = useState([]);
  const [columns, setColumns] = useState([])
  const [jobList, setJobList] = useState([])
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [assignedUser, setAssignedUser] = useState([]);
  const [checkboxStates, setCheckboxStates] = useState([]);
  const [updatedtaskData, setUpdatetaskdData] = useState(null)
  const [formError, setFormError] = useState({})
  const [selectedTasks, setSelectedTasks] = useState(null)
  const [jobFilterArr, setJobFilterArr] = useState([])
  const [cliFilterArr, setCliFilterArr] = useState([])
  const [statusFilterArr, setStatusFilterArr] = useState([])
  const { globalSearch, resetSearch } = useContext(GlobalSearch)
  const [isCallEntry, setIsCallEntry] = useState(false)
  const [fileList, setFileList] = useState([]);
  const [taskForActivity, setTaskForActivity] = useState(null)
  const {
    jobOpenPopup, jobSectionCodes, JobPaymentTerms, JobCustomerList, selectedJobForSection, setSelectedJobForSection,
    JobResponsibleUser, JobFormError, setJobFormError, updateJobCancelClickHandler, updateJobPopupOpen, jobSuccessMessage, setJobSuccessMessage
  } = useContext(JobContext)
  const { openCustomerPopup, customerPaymentTerms, customerStatus, customerCountries, customerStates, customerCities,
    selectedCustomer, customerIbUsers, customerFormError, setCustomerFormError, setCustomerUserDetail, customerCreditFormError,
    setCustomerCreditFormError, customerCreditEditFormError, setCustomerCreditEditFormError, customerCreditAddFormValue,
    setCustomerCreditAddFormValue, customerCreditEditFormValue, setCustomerCreditEditFormValue, customerCreditIsEditMode,
    setCustomerCreditIsEditMode, customerUpdatedData, setCustomerUpdatedData, updateCustomerCancelClickHandler,
    updateCustomerPopupOpen } = useContext(CustomerContext)
  const { isJob, jobActivePopup, jobActivityNotes, setJobActivityNotes, selectedJobForActivity, jobfileList, 
    JobdocumentUploadCustomRequest, handleJobDocRemove, handleJobDocDownload, jobdocOnChangehandler, getActivityNotesByJobId, 
    closeJobActivityPopup, setSelectedJobForActivity, onNoteButtonClick } = useContext(ActivityNotesContext)
  const [CallEnterypopUps, setCallEnterypopUps] = useState(false);
  const [callEntryIbUsers, setCallEntryIbUsers] = useState([]);
  let navigate = useNavigate()
  const [fetchedTaskdata, setFetchedTaskData] = useState([]);
  const [taskSuccessMessage, setTaskSuccessMessage] = useState("");
  const [callSuccessMessage, setCallSuccessMessage] = useState("");
  const [sorterVal, setSorterVal] = useState(null)
  const [billWarning, setBillWarning] = useState(false)
  const [tempValues, setTempValues] = useState({});
  const { confirmDelete, DeleteConfirmModal, confirmDeleteWindow } = useConfirm();
  const [jobRequestedBy, setJobRequestedBy] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();

  const options = [
    { value: '1', label: 'To do', id: "1" },
    { value: '2', label: 'In Progress', id: "2" },
    { value: '4', label: 'Review', id: "4" },
    { value: '3', label: 'Done', id: "3" },
  ];

  useEffect(() => {
    const initialStates = taskDetail?.reduce((acc, record) => {
      const storedState = localStorage.getItem(`checkboxState_${record.id}`);
      acc[record.id] = storedState ? JSON.parse(storedState) : false;
      return acc;
    }, {});
    setCheckboxStates(initialStates);
  }, [taskDetail]);

  const handleCheckboxChange = async (userId) => {
    try {
      const updatedCheckboxStates = { ...checkboxStates, [userId]: !checkboxStates[userId] };
      const payload = { checked: updatedCheckboxStates[userId] };
      const response = await patchCustomerReview(userId, payload);
      // Update the local state using the updatedCheckboxStates
      setCheckboxStates(updatedCheckboxStates);
    } catch (error) {
      console.error('Error updating review:', error);
    }
  };

  const deletTaskData = async (record) => {
    let taskData = await getSingleTaskData(record.id)
    let msg = (taskData?.Task?.billable_items?.is_billed == 1 || taskData?.Task?.billable_items_job?.is_billed == 1) ? "The task you are attempting to delete has already been billed. Deleting this task will affect generated bills.\n\nAre you sure you want to delete this task?" : "Are you sure you want to delete this task?"
    const isConfirmed = await confirmDeleteWindow(msg)
    if (!isConfirmed) {
      return;
    }
    try {
      await deleteTask(record.id);
      getTaskListPagination(paginationData.per_page, paginationData.current_page, filters, false)
    } catch (error) {
      console.error('Error deleting task:', error.message);
    }
  };

  const duplicateTask = async (record) => {
    let isConfirm = confirmWindow("Are you sure you want to duplicate this task?")
    if (isConfirm) {
      try {
        const taskIds = record.id;
        const payload = {
          task_id: taskIds,
        };
        const response = await postDuplicateTaskData(payload);
        getTaskListPagination(paginationData.per_page, paginationInitialPage, filters, false)
      } catch (error) {
        console.error('Error saving data:', error.message);
      }
    }
  };

  // On pagination change handler
  const handleOnPageChange = (pageNumber) => {
    getTaskListPagination(paginationData.per_page, pageNumber, filters, true)
  }

  // custom filter check handler
  const customFilterHandler = (opt) => {
    let fils = { ...filters, global_search: "" }
    if (opt?.key) {
      fils[opt.key] = opt?.id ? [opt?.id] : []
    }
    if (opt?.key == "job_id") {
      fils['task_id'] = []
    }
    let { global_search, ...saveFilter } = fils
    saveFilterToLocal('tasks', saveFilter)
    resetSearch()
    if (globalSearch == "") {
      getTaskListPagination(paginationData.per_page, paginationData.current_page, fils, true)
    }
    setFilters(fils)
  }

  // custom filter reset handler
  const customFilterResetHandler = (key) => {
    let search = { ...filters, [key]: [] }
    let { global_search, ...saveFilter } = search
    saveFilterToLocal('tasks', saveFilter)
    getTaskListPagination(paginationData.per_page, paginationData.current_page, search, true)
  }

  // On cell handler
  const onCellHandler = () => {
    return { onClick: (event) => { event.stopPropagation() } }
  }

  // On job click handler
  const onJobNameClickHandler = (e, record) => {
    e.stopPropagation()
    updateJobPopupOpen(record?.project_id)
  }

  // On cell handler
  const onCustomerNameClickcHandler = (e, record) => {
    e.stopPropagation()
    updateCustomerPopupOpen(record?.customer_id)
  }

  useEffect(() => {
    let userType = localStorage.getItem('usertype');
    let columnsDef = [
      {
        title: "",
        width: 50,
        key: "taskBeing",
        onCell: onCellHandler,
        render: (text, record) => {
          return (
            <TaskBeingWorkedOn record={record} paginationData={paginationData} filters={filters} getTaskListPagination={getTaskListPagination} />
          )
        },
      },
      {
        title: "Customer Name",
        dataIndex: ["customer_name"],
        key: "clientName",
        width: 180,
        render: (text, record) => {
          return text ? <div>
            {(userType == 1 || userType == 2) ? <Tooltip placement="top" title={"Go To Customer"}>
              <FaSquareArrowUpRight className="redirect-icon text-danger" onClick={(e,) => onCustomerNameClickcHandler(e, record)} />
              <span onClick={(e,) => onCustomerNameClickcHandler(e, record)} className="ms-1">{text}</span>
            </Tooltip> : text}
          </div> : "";
        },
        sorter: true,
        sortOrder: getSortOrder(filters.sorter, taskColumns.clientName)
      },
      {
        title: "Job",
        dataIndex: ["project_name"],
        key: "job",
        width: 220,
        render: (text, record) => {
          return text ? <div>
            {(userType == 1 || userType == 2) ? <Tooltip placement="top" title={"Go To Job"}>
              <FaSquareArrowUpRight className="redirect-icon text-danger" onClick={(e) => onJobNameClickHandler(e, record)} />
              <span onClick={(e) => onJobNameClickHandler(e, record)} className="ms-1">{text}</span>
            </Tooltip> : text}
          </div> : "";
        },
        filteredValue: filters.job_id,
        sorter: true,
        sortOrder: getSortOrder(filters.sorter, taskColumns.job),
        filterDropdown: (props) => { return <JobFilter {...props} subsection={'tasks'} filters={filters} setFilters={setFilters} onFilter={customFilterHandler} onReset={customFilterResetHandler} section={'task'} /> },
      },
      {
        title: "Task Name",
        dataIndex: "name",
        key: "taskName",
        width: 190,
        sorter: true,
        sortOrder: getSortOrder(filters.sorter, taskColumns.taskName),
        render: (text, record) => {
          return (
            <div>{record.priority == 2 && <Tooltip placement="top" title={"High Priority"}><span><FaArrowAltCircleUp className="redirect-icon text-danger" /></span></Tooltip>} {text}</div>
          );
        },
        filteredValue: filters.task_id,
        filterDropdown: (props) => { return <TaskFilter {...props} subsection={'tasks'} filters={filters} setFilters={setFilters} onFilter={customFilterHandler} onReset={customFilterResetHandler} /> },
      },
      {
        title: "Due Date",
        dataIndex: "desired_due_date",
        key: "desiredDueDate",
        width: 140,
        sorter: true,
        sortOrder: getSortOrder(filters.sorter, taskColumns.desiredDueDate),
        render: (text, record) => {
          return record?.desired_due_date ? <div>{parseDateTimeString(record?.desired_due_date, 14)} {isOverdue(record?.desired_due_date) && <Tooltip placement="top" title={"Task Overdue"}><span><BsExclamationCircleFill className="redirect-icon text-danger" /></span></Tooltip>}</div> : null
        }
      },
      {
        title: "Assignee",
        dataIndex: "assigned_user_details",
        key: "assignto",
        width: 160,
        filteredValue: filters.assignee,
        filterDropdown: (props) => { return <AssigneeFilter {...props} filters={filters} setFilters={setFilters} onFilter={customFilterHandler} onReset={customFilterResetHandler} taskAssigneeList={taskAssigneeList} userType={userType} /> },
        render: (_, record) => {
          return <AssignedUserList record={record} />
        },
      },
      {
        title: "Date Completed",
        dataIndex: "completion_date",
        key: "completion_date",
        width: 150,
        sorter: true,
        sortOrder: getSortOrder(filters.sorter, taskColumns.completion_date),
        render: (text, record) => (
          <span>
            {record.completion_date ? parseDateTimeString(record.completion_date, 14) : null}
          </span>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: 160,
        filteredValue: filters.status,
        onCell: onCellHandler,
        filterDropdown: (props) => { return <StatusFilter {...props} filters={filters} setFilters={setFilters} onFilter={customFilterHandler} onReset={customFilterResetHandler} /> },
        render: (text, record) => (
          <>
            <EditableText
              record={record}
              options={options}
              paginationData={paginationData}
              getTaskListPagination={getTaskListPagination}
              filters={filters}
              setBillWarning={setBillWarning}
              setTempValues={setTempValues}
            />
          </>
        ),
      },
      {
        title: "Customer Review",
        dataIndex: "customer_review",
        key: "customerreview",
        width: 170,
        filteredValue: filters.customer_review,
        filterDropdown: (props) => { return <CustomerReviewFilter {...props} filters={filters} setFilters={setFilters} onFilter={customFilterHandler} onReset={customFilterResetHandler} /> },
        render: (text, record) => (
          <TaskReview record={record} handleCheckboxChange={handleCheckboxChange} />
        ),
      },
      {
        title: 'Actions',
        key: 'actions',
        width: 100,
        className: "actions-column",
        width: 140,
        onCell: onCellHandler,
        render: (text, record) => (
          <>
            <div className="d-flex">
              <MyTooltip title="Edit Task">
                <TableBtn className="activeLog update-task-btn" onclick={() => updatePopUps(record)}>
                  <BiSolidEdit />
                </TableBtn>
              </MyTooltip>
              {(userType == 1 || userType == 2) && <div className="mx-2">
                <TableBtn className="activeLog update-task-btn" >
                  <AssignUser assignedUser={assignedUser} setAssignedUser={setAssignedUser} record={record} paginationData={paginationData}
                    getTaskListPagination={getTaskListPagination} selectedTasks={selectedTasks} setSelectedTasks={setSelectedTasks} getTaskList={getTaskList} filters={filters} />
                </TableBtn >
              </div>}
              {((userType == 1 || userType == 2) && record?.name != "Call Time Entry") && <MyTooltip title="Duplicate Task">
                <TableBtn className=" activeLog update-task-btn " onclick={() => duplicateTask(record)}>
                  <HiOutlineDocumentDuplicate />
                </TableBtn>
              </MyTooltip>}
              {((userType == 1 || userType == 2) && (record?.status != 3)) && <div className="mx-2">
                <MyTooltip title="Delete Task">
                  <TableBtn className=" activeLog update-task-btn " onclick={() => deletTaskData(record)}>
                    <RiIcons.RiDeleteBin6Fill />
                  </TableBtn>
                </MyTooltip>
              </div>}
            </div>
          </>
        ),
      },
    ];

    if (userType == '3') {
      // If user type is 3, hide the 'taskBeing' and 'customer review' columns
      columnsDef = columnsDef.filter(column => column.key !== "taskBeing" && column.key !== "customerreview");
    }

    setColumns(columnsDef)
  }, [taskDetail, jobList, selectedTasks, assignedUser, jobFilterArr, cliFilterArr, statusFilterArr, filters, tempValues, taskAssigneeList])

  function getJobList() {
    getJob().then((res) => {
      setJobList(res.data.Projects)
    }).catch(err => { setJobList([]) })
  }

  function getActivityNotesByTaskId(id) {
    fetchActivityNotesByTaskId(id).then((res) => {
      setActivityNotes(res.data)
    }).catch(() => { setActivityNotes([]) })
  }

  // Get single task data by ID
  function getSingleTaskData(id) {
    return getSingleTask(id).then((res) => {
      return res.data
    }).catch(() => {
      return
    })
  }

  const getIbUsers = () => {
    // 2: IB users
    fecthUsersWithType(2).then((res) => {
      if (res.data?.users) { setCallEntryIbUsers(res.data?.users) }
    }).catch(() => { setCallEntryIbUsers([]) })
  }

  const updatePopUps = (data) => {
    if (data?.name == "Call Time Entry") {
      setIsCallEntry(true)
      getIbUsers()
      getSingleTaskData(data.id).then((res) => {
        if (res?.Task) {
          settaskUpdateDetail(res.Task)
        }
      }).catch(() => {
        settaskUpdateDetail([])
      })
      setCallEnterypopUps(true);
    } else {
      getServiceTypes();
      setIsCallEntry(false)
      getSingleTaskData(data.id).then((res) => {
        if (res?.Task) {
          setFetchedTaskData(res.Task)
        }
      }).catch(() => {
        setFetchedTaskData([])
      })
      setPopUps(true);
    }
    setFormError({})
    getCustomersList();
    setTaskSuccessMessage("")
    setCallSuccessMessage("")
  }

  const closeCallEntryPopup = () =>{
    setCallEnterypopUps(false);
    setFormError({})
    setUpdatetaskdData({})
    settaskUpdateDetail(null)
    settaskUpdateDetail(null)
    setFetchedTaskData(null)
  }
  const onCancelBtnHandler = () => {
    setIsCallEntry(false)
    setFormError({})
    setPopUps(false);
    setUpdatetaskdData({})
    settaskUpdateDetail(null)
    setFetchedTaskData(null)
  }

  const openActivityLogs = (record) => {
    setJobRequestedBy(record?.customer_id)
    getSingleTaskData(record.id).then((res) => {
      if (res?.Task) {
        setTaskForActivity(res.Task)
      }
    }).catch(() => {
      setTaskForActivity([])
    })
    setActivePopUps(true)
    getActivityNotesByTaskId(record.id)
  }

  const onRowClick = (e, record) => {
    const target = e.target
    if (!target.className.includes('update-task-btn')) {
      openActivityLogs(record)
    }
  }

  // table onChange handler function 
  const onTableChangeHandler = (pagination, filters, sorter, extra) => {
    setFilters(prev => {
      if (!sorter || Object.keys(sorter).length === 0) {
        return { ...prev, sorter: null };
      }
      return {
        ...prev, sorter: {
          field: taskColumns[sorter.columnKey] ? taskColumns[sorter.columnKey] : "",
          order: sorter.order ? sorter.order == "descend" ? -1 : 1 : null
        }
      };
    });
    const { currentDataSource } = extra
    if (filters.job) {
      setJobFilterArr(filters.job)
    } else {
      setJobFilterArr([])
    }
    if (filters.clientName) {
      setCliFilterArr(filters.clientName)
    } else {
      setCliFilterArr([])
    }
    if (filters.status) {
      setStatusFilterArr(filters.status)
    } else {
      setStatusFilterArr([])
    }
    if (sorter && Object.keys(sorter).length != 0 && pagination?.current == paginationData.current_page) {
      setSorterVal(`${sorter.columnKey}-${sorter.order}`)
    } else {
      setSorterVal(null);
    }
  }

  const onCancelButtonClick = () => {
    setActivePopUps(false)
    setTaskForActivity(null)
    setActivityNotes([])
    setFileList([])
    setJobRequestedBy(null)
    getTaskListPagination(paginationData.per_page, paginationData.current_page, filters, false)
  }

  useEffect(() => {
    if (taskForActivity) {
      const doc = taskForActivity?.activity_document_details?.map((doc) => {
        let parts = doc.document_url.split("/")
        let name = doc?.document_name
        let originalName = getOriginalFileName(name)
        return { uid: doc.document_id, name: originalName ? originalName : "Document", status: 'done', url: doc.document_url }
      })
      setFileList(doc)
    }
  }, [taskForActivity])

  // Document upload handler
  const documentUploadCustomRequest = (data) => {
    const { onSuccess, onError, onProgress } = data
    const fileExtension = data.file?.name?.split('.').pop().toLowerCase()
    if (!taskActivityDocExtentions.includes(fileExtension)) {
      const formData = new FormData()
      formData.append('documents1', data.file)
      formData.append('id', taskForActivity.id)

      const config = {
        onUploadProgress: (e) => {
          onProgress({ percent: (e.loaded / e.total) * 100 })
        }
      }
      uploadTaskActivityDocument(formData, config).then((res) => {
        onSuccess(res.data)
      }).catch(err => {
        onError({ message: err.response?.data.message || "Failed to upload document" })
      })
    } else {
      onError({ message: fileExtension ? `Failed to upload .${fileExtension} file` : "Failed to upload file" })
    }
  }

  // Docuemnt remove function
  function removeDocument(id) {
    deleteTaskActivityDocument(id).then(() => {
      setFileList((prev) => prev.filter((item) => item.uid != id))
    }).catch((err) => {
      console.log("deleteTaskActivityDocument-err", err)
    })
  }

  // Document remove Handler
  const handleRemove = async (e) => {
    let isConfirm = await confirmDelete("document")
    if (isConfirm) {
      if (e.status == "error") {
        setFileList((prev) => { return prev.filter((item) => item.uid != e.uid) })
      } else {
        removeDocument(e.uid)
      }
    }
  }

  // Document download Handler
  const handleDownload = (e) => {
    downloadFile(e.url, e.name)
  }

  // Document onchange Handler
  const docOnChangehandler = (e) => {
    if (e.file.status == "done") {
      let items = [...fileList]
      let newArr = items.map((item) => {
        if (item.uid == e.file.uid) {
          let parts = e.file.response?.url?.split("/")
          let name = parts[parts.length - 1]
          let originalName = getOriginalFileName(name)
          return { uid: e.file.response.document_id, name: originalName ? originalName : "Document", status: 'done', url: e.file.response.url }
        }
        return item
      })
      setFileList(newArr)
    } else {
      let files = moveFileToFront(e.file, e.fileList)
      setFileList(files)
    }
  }

  const sendAlert = ({ type, content, className, duration }) => {
    if (content) {
      messageApi.open({
        type: type || 'success',
        content: content,
        className: `alert-message ${className ? className : ''}`,
        duration: duration ? duration : 3 
      });
    }
  }

  useEffect(() => {
    const taskId = queryParams.get('task_id');
    const isEmailLink = queryParams.get('isEmailLink');
    const isCopied = queryParams.get('c');
    const openWindow = queryParams.get('w');
    const isAssigneeLink = queryParams.get('isAssigneeLink');
    const userId = queryParams.get('user_id');
    const isCustomerReview = queryParams.get('isCustomerReview');
    let userType = localStorage.getItem('usertype')
    const customer_id = queryParams.get('customer_id');
    let token = localStorage.getItem("token")
    let localUserId = localStorage.getItem("id")
    if(isCustomerReview == "False" && userType == 3){
      sendAlert({ type: 'error', content: 'The task has not been set for customer review yet. Please contact the administrator', duration: 6 })
      navigate("/tasks")
    } else{
      if (isEmailLink == "true" && isAssigneeLink == "True") {
        if (token && (userId == localUserId)) {
          openActivityLogs({ id: taskId, customer_id: customer_id })
        } else {
          navigate("/")
        }
      } else if (isEmailLink == "true") {
        if (token && (userId == localUserId)) {
          openActivityLogs({ id: taskId, customer_id: customer_id })
        } else {
          navigate("/")
        }
      } else if (isCopied == "true") {
        if (token && openWindow == "e") {
          updatePopUps({ id: taskId })
        } else if (token && openWindow == "ce") {
          updatePopUps({ id: taskId, name: "Call Time Entry" })
        } else if (token && openWindow == "a") {
          openActivityLogs({ id: taskId, customer_id: customer_id })
        } else {
          navigate("/")
        }
      }
    }
  }, [])


  useEffect(() => {
    if (sorterVal && !isInitialCall) {
      getTaskListPagination(paginationPerPage, 1, filters, true)
    }
  }, [sorterVal])

  const fetchUpdatedTaskList = () => {
    getTaskListPagination(paginationData.per_page, paginationData.current_page, filters, false)
  }
  const handleSelect = async (payload) => {
    patchAssignedUserData(payload.task_id, payload).then(() => {
      setTempValues({});
      getTaskListPagination(paginationData.per_page, paginationData.current_page, filters, false)
    }).catch((err) => {
      console.log("TaskStatusChange-err: ", err)
    })
  };

  const onBilledOkButtonClick = () => {
    setBillWarning(false);
    handleSelect(tempValues)
  };

  const onBilledNoButtonClick = () => {
    setBillWarning(false);
    setTempValues({});
  };

  const openJobNotes = (e, data) => {
    if (data?.project) {
      let payload = { job_id: data?.project }
      onNoteButtonClick(e, payload)
    }
  }

  return (
    <>
    {contextHolder}
      <div className="custTable">
        <Table
          columns={columns}
          dataSource={taskDetail}
          scroll={{ y: `calc(100vh - 240px)` }}
          onRow={(record, rowIndex) => ({
            onClick: (e) => { onRowClick(e, record) }
          })}
          loading={{
            indicator: <LoadingOutlined style={{ fontSize: 50, color: '#2c0036' }} spin />,
            spinning: isLoading,
          }}
          onChange={onTableChangeHandler}
          pagination={{
            position: ['bottomRight'],
            pageSize: paginationData.per_page,
            current: paginationData.current_page,
            showSizeChanger: paginationSizeChanger,
            total: paginationData.total,
            onChange: handleOnPageChange
          }}
          footer={() => {
            return paginationData.total ? <div className="text-end d-flex justify-content-between align-items-center">
              <p className="mb-0">{calculatePageRange(paginationData.current_page, paginationData.per_page, paginationData.total)}</p>
            </div> : null
          }}
          rowClassName={(record, index) => {
            return record.priority == 2 ? "high-priority-task" : ""
          }}
        />
      </div>

      <div className={`${popUps ? "mainpopups" : "nomainpopups"}`}>
        <TaskUpdate
          serviceTypes={serviceTypes}
          paymentTerms={paymentTerms}
          customerList={customerList}
          getTaskList={getTaskList}
          taskUpdateDetail={fetchedTaskdata}
          onClick={onCancelBtnHandler}
          formError={formError}
          setFormError={setFormError}
          paginationData={paginationData}
          getTaskListPagination={getTaskListPagination}
          updatedtaskData={updatedtaskData}
          setUpdatetaskdData={setUpdatetaskdData}
          settaskUpdateDetail={setFetchedTaskData}
          isCallEntry={isCallEntry}
          filters={filters}
          successMessage={taskSuccessMessage}
          setSuccessMessage={setTaskSuccessMessage}
        />
        <div className="blurBg"></div>
      </div>
      <div className={`${CallEnterypopUps ? "mainpopups" : "nomainpopups"}`}>
        <CallEntryPopup
          serviceTypes={serviceTypes}
          paymentTerms={paymentTerms}
          customerList={customerList}
          getTaskList={getTaskList}
          taskUpdateDetail={taskUpdateDetail}
          onClick={closeCallEntryPopup}
          formError={formError}
          setFormError={setFormError}
          paginationData={paginationData}
          getTaskListPagination={getTaskListPagination}
          updatedtaskData={updatedtaskData}
          setUpdatetaskdData={setUpdatetaskdData}
          settaskUpdateDetail={settaskUpdateDetail}
          isCallEntry={isCallEntry}
          filters={filters}
          ibUsers={callEntryIbUsers}
          successMessage={callSuccessMessage}
          setSuccessMessage={setCallSuccessMessage}
        />
        <div className="blurBg"></div>
      </div>
      {activepopUps && <div className={`mainpopups`}>
        <ActivityPopups
          onClick={onCancelButtonClick}
          activityNotes={activityNotes} taskId={taskId} setActivityNotes={setActivityNotes} selectedTask={taskForActivity}
          getActivityNotesByTaskId={getActivityNotesByTaskId}
          multiple={true}
          documentUploadCustomRequest={documentUploadCustomRequest}
          fileList={fileList}
          docOnChangehandler={docOnChangehandler}
          handleDownload={handleDownload}
          handleRemove={handleRemove}
          fetchList={fetchUpdatedTaskList}
          setSelectedTask={setTaskForActivity}
          openJobNotes={openJobNotes}
          isCustomerReview={true}
          jobRequestedBy={jobRequestedBy}
        />
        <div className="blurBg"></div>
      </div>}
      {jobOpenPopup && <div className={`mainpopups`}>
        <UpdateForm
          jobCodes={jobSectionCodes}
          paymentTerms={JobPaymentTerms}
          customerList={JobCustomerList}
          getJobList={getJobList}
          selectedJob={selectedJobForSection}
          onClick={updateJobCancelClickHandler}
          responsibleUser={JobResponsibleUser}
          formError={JobFormError}
          setFormError={setJobFormError}
          getJobListPagination={getTaskListPagination}
          paginationData={paginationData}
          setSelectedJob={setSelectedJobForSection}
          filters={filters}
          setFilters={setFilters}
          successMessage={jobSuccessMessage}
          setSuccessMessage={setJobSuccessMessage}
        />
        <div className="blurBg"></div>
      </div>}
      {openCustomerPopup && <div className={`mainpopups`}>
        <UpdateCust
          paymentTerms={customerPaymentTerms}
          clientStatus={customerStatus}
          countries={customerCountries}
          states={customerStates}
          cities={customerCities}
          getCustomersList={getTaskListPagination}
          selectedCustomer={selectedCustomer}
          onClick={updateCustomerCancelClickHandler}
          ibUsers={customerIbUsers}
          formError={customerFormError}
          setFormError={setCustomerFormError}
          paginationData={paginationData}
          getCustomersListPagination={getTaskListPagination}
          setUserDetail={setCustomerUserDetail}
          creditFormError={customerCreditFormError}
          setCreditFormError={setCustomerCreditFormError}
          creditEditFormError={customerCreditEditFormError}
          setCreditEditFormError={setCustomerCreditEditFormError}
          creditAddFormValue={customerCreditAddFormValue}
          setCreditAddFormValue={setCustomerCreditAddFormValue}
          creditEditFormValue={customerCreditEditFormValue}
          setCreditEditFormValue={setCustomerCreditEditFormValue}
          creditIsEditMode={customerCreditIsEditMode}
          setCreditIsEditMode={setCustomerCreditIsEditMode}
          updatedData={customerUpdatedData}
          setUpdatedData={setCustomerUpdatedData}
          isFromCustomer={false}
          filters={filters}
        />
        <div className="blurBg"></div>
      </div>}
      {billWarning && <WarningDialog
        okClick={onBilledOkButtonClick}
        noClick={onBilledNoButtonClick}
        title={"Warning: Already Billed"}
        description={"This item is already billed or part of a billed item, the requested change would result in an update to the amount billed. Would you like to proceed?"}
      />}
      {jobActivePopup && <div className={`mainpopups`}>
        <ActivityPopups
          onClick={closeJobActivityPopup}
          activityNotes={jobActivityNotes}
          setActivityNotes={setJobActivityNotes}
          selectedJob={selectedJobForActivity}
          getActivityNotesByTaskId={getActivityNotesByJobId}
          multiple={true}
          documentUploadCustomRequest={JobdocumentUploadCustomRequest}
          fileList={jobfileList}
          docOnChangehandler={jobdocOnChangehandler}
          handleDownload={handleJobDocDownload}
          handleRemove={handleJobDocRemove}
          isJob={isJob}
          fetchList={() => { }}
          setSelectedJob={setSelectedJobForActivity}
        />
        <div className="blurBg"></div>
      </div>}
      {<DeleteConfirmModal />}
    </>
  );
};

export default TasksTable;
