/* eslint-disable no-unused-vars */
import '../../SCSS/editTooltip.scss';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import '../../SCSS/popups.scss';
import "../../SCSS/ContactPage.scss"
import Button from '../../commonModules/UI/Button';
import React, { useEffect, useState } from 'react';
import { Popover, Space, Tooltip } from 'antd';
import { IoMdInformationCircle } from "react-icons/io";
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { IoChevronDownOutline } from "react-icons/io5";

const PhoneNumberPopover = ({ onApplyClick, name, type, placeholder, value, placement, disabled, isFormatted }) => {
    const [open, setOpen] = useState(false);
    const [phoneValue, setPhoneValue] = useState('');
    const [withExtension, setWithExtension] = useState(true);
    const [formValues, setFormValues] = useState({ phoneNumber: "", extension: "" });
    const [isValid, setIsValid] = useState("");

    useEffect(() => {
        if (!withExtension) {
            setFormValues((prev) => ({ ...prev, extension: "" }));
        }
    }, [withExtension]);

    useEffect(() => {
        if (typeof phoneValue === 'string' || isFormatted) {
            let data = phoneValue?.split("ext");
            setFormValues((prev) => ({ ...prev, phoneNumber: data?.[0]?.trim() }));

            if (data?.[0]?.trim()) {
                setIsValid(isValidPhoneNumber(data?.[0]?.trim()));
            }
            if (data?.[1]?.trim() || isFormatted) {
                setWithExtension(true);
                setFormValues((prev) => ({ ...prev, extension: data?.[1]?.trim() }));
            } else {
                setWithExtension(false);
            }
        }
    }, [phoneValue, isFormatted]);

    // const changeFieldsClick = () => {
    //     const newExtensionState = !withExtension;
    //     const containsLetters = /[a-zA-Z]/.test(formValues.phoneNumber);
    //     if (containsLetters) {
    //         setFormValues((prev) => ({ ...prev, phoneNumber: "" }));
    //         setIsValid(false);
    //     } else if (newExtensionState && formValues.phoneNumber.trim()) {
    //         setIsValid(isValidPhoneNumber(formValues.phoneNumber));
    //     }
    //     setWithExtension(newExtensionState);
    // };

    const changeFieldsClick = () => {
        const newExtensionState = !withExtension;

        if (newExtensionState) {
            let data = formValues.phoneNumber?.split("ext");
            setFormValues({
                phoneNumber: data?.[0]?.trim(),
                extension: data?.[1]?.trim() || ""
            });
        } else {
            const unformattedValue = formValues.phoneNumber + (formValues.extension ? " ext " + formValues.extension : "");
            setFormValues((prev) => ({ ...prev, phoneNumber: unformattedValue, extension: "" }));
        }

        setWithExtension(newExtensionState);
    };

    const onApplyButtonHandler = () => {
        let phoneFieldVal = `${formValues.phoneNumber} ${formValues.extension ? "ext " + formValues.extension : ''}`.trim();
        let isFormatted = withExtension;
        onApplyClick(phoneFieldVal, isFormatted);
        setOpen(false);
    };

    const onChangePhoneNumber = (value) => {
        if (typeof value === 'string') { setIsValid(isValidPhoneNumber(value)); }
        setFormValues((prev) => ({ ...prev, phoneNumber: value }));
    };

    const onChangeExtension = (e) => {
        const extVal = e.target.value;
        if (/^\d*$/.test(extVal)) {
            setFormValues((prev) => ({ ...prev, extension: extVal }));
        }
    };

    const singleFieldOnChange = (e) => {
        setFormValues((prev) => ({ ...prev, phoneNumber: e.target.value }));
    };

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
        if (!newOpen) {
            setFormValues({ phoneNumber: "", extension: "" });
        }
    };

    const onCancelClick = () => {
        setOpen(false);
        setFormValues({ phoneNumber: "", extension: "" });
    };

    useEffect(() => {
        if (open) {
            setPhoneValue(value);
        } else {
            setPhoneValue('');
        }
    }, [open, value]);

    return (
        <Space wrap style={{ width: '100%' }}>
            <Popover
                placement={placement || 'right'}
                content={
                    <div className='popoverWidth'>
                        <div className='border-wrap'>
                            <div className='phone-num-wrap'>
                                {withExtension ? <>
                                    <PhoneInput
                                        placeholder="Phone Number"
                                        value={formValues.phoneNumber ?? ""}
                                        onChange={onChangePhoneNumber}
                                        className='phone-num'
                                        defaultCountry='US'
                                    />
                                    <FloatingLabel label="Extension" className='extension'>
                                        <Form.Control
                                            type="text"
                                            name='extension'
                                            value={formValues.extension ?? ""}
                                            onChange={onChangeExtension}
                                            placeholder='Extension'
                                        />
                                    </FloatingLabel>
                                </> : <FloatingLabel label="Phone Number" className='phone-num'>
                                    <Form.Control
                                        type="text"
                                        name='phoneNumber'
                                        value={formValues.phoneNumber ?? ""}
                                        placeholder='Phone Number'
                                        onChange={singleFieldOnChange}
                                    />
                                </FloatingLabel>}
                            </div>
                            {withExtension && <>{!formValues?.phoneNumber ? <p className='mb-0 mt-1 help-text'>Select a country code and enter a phone number.</p> :
                                isValid ? <p className='mb-0 mt-1 help-text'>Validated</p> : <p className='mb-0 mt-1 help-text text-danger'>This phone number is either invalid or is in the wrong format.</p>
                            }</>}
                        </div>
                        <p className='removelink-wrap'>
                            <span className='removelink mt-2' onClick={changeFieldsClick}>{withExtension ? "Remove number formatting" : "Apply number formatting"}</span>
                            <Tooltip title={withExtension ? "This will remove all formatting and requirements in the field. You’ll be able to enter any characters without auto-formatting being applied." : "This will insert standard formatting based on the phone number's country code."}>
                                <IoMdInformationCircle />
                            </Tooltip>
                        </p>
                        <div className='d-flex align-items-center justify-content-center gap-3 mt-3'>
                            <Button className="cclBtn" onClick={onCancelClick}>Cancel</Button>
                            <Button type="button" disabled={withExtension && (!formValues?.phoneNumber?.trim() || !isValid)} onClick={onApplyButtonHandler}>Apply</Button>
                        </div>
                    </div>
                }
                trigger="click"
                open={open}
                onOpenChange={handleOpenChange}
                getPopupContainer={(triggerNode) => document.body}
            >
                <div className={`inputSelectWrap ${disabled ? 'disabled-label' : ''}`}>
                    <FloatingLabel label={placeholder}>
                        <Form.Control
                            type={type}
                            name={name}
                            value={value}
                            placeholder={placeholder}
                            disabled={disabled || false}
                            autoComplete='off'
                        />
                    </FloatingLabel>
                </div>
            </Popover>
        </Space>
    )
};
export default PhoneNumberPopover;